/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SumParams { 
    /**
     * Sum aggregation.
     */
    type?: SumParams.TypeEnum;
    /**
     * Columns to sum.
     */
    columns: Array<string>;
}
export namespace SumParams {
    export type TypeEnum = 'sum';
    export const TypeEnum = {
        Sum: 'sum' as TypeEnum
    };
}


