/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Request { 
    name?: string;
    currency?: string;
    amount?: string;
    product_type?: Request.ProductTypeEnum;
    product_reference?: string;
    quantity?: number;
}
export namespace Request {
    export type ProductTypeEnum = 'INSTANCE' | 'BANDWIDTH' | 'SUPPORT';
    export const ProductTypeEnum = {
        Instance: 'INSTANCE' as ProductTypeEnum,
        Bandwidth: 'BANDWIDTH' as ProductTypeEnum,
        Support: 'SUPPORT' as ProductTypeEnum
    };
}


