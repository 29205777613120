/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Filter values that are not null.  Attributes ---------- type : RelationalOperatorType     Type of the filter. right : str     Optional value for right side of the filter.
 */
export interface NotNullFilter { 
    /**
     * Left operator for filter.
     */
    left: string;
    right?: string;
    /**
     * Operator for NOT NULL filter.
     */
    type?: NotNullFilter.TypeEnum;
}
export namespace NotNullFilter {
    export type TypeEnum = 'not_null';
    export const TypeEnum = {
        NotNull: 'not_null' as TypeEnum
    };
}


