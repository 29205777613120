/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MailNotificationAllOf { 
    type?: string;
    to?: string;
    subject?: string;
    from?: string;
    username?: string;
    password?: string;
    host?: string;
    port?: number;
}

