/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Workflow1 } from './workflow1';
import { User1 } from './user1';
import { Activity } from './activity';
import { Reaction } from './reaction';
import { WorkflowActivityAllOf } from './workflowActivityAllOf';


/**
 * Object JobActivity.
 */
export interface WorkflowActivity { 
    data?: object;
    id?: string;
    time?: string;
    reaction_count?: Array<Reaction>;
    type?: WorkflowActivity.TypeEnum;
    actor: User1;
    target: Workflow1;
    verb: string;
}
export namespace WorkflowActivity {
    export type TypeEnum = 'workflow';
    export const TypeEnum = {
        Workflow: 'workflow' as TypeEnum
    };
}


