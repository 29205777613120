/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User1AllOf } from './user1AllOf';
import { Resource } from './resource';


/**
 * Object User.
 */
export interface User1 { 
    id: string;
    type?: User1.TypeEnum;
}
export namespace User1 {
    export type TypeEnum = 'user';
    export const TypeEnum = {
        User: 'user' as TypeEnum
    };
}


