/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectActivityAllOf } from './projectActivityAllOf';
import { User1 } from './user1';
import { Activity } from './activity';
import { Project1 } from './project1';
import { Reaction } from './reaction';


/**
 * Object ProjectActivity.
 */
export interface ProjectActivity { 
    data?: object;
    id?: string;
    time?: string;
    reaction_count?: Array<Reaction>;
    type?: ProjectActivity.TypeEnum;
    actor: User1;
    target: Project1;
    verb: string;
}
export namespace ProjectActivity {
    export type TypeEnum = 'project';
    export const TypeEnum = {
        Project: 'project' as TypeEnum
    };
}


