/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Details } from './details';


export interface Ticket { 
    id?: string;
    tenant_id?: string;
    status?: Ticket.StatusEnum;
    title?: string;
    description?: string;
    details?: Details;
    created?: string;
    updated?: string;
    metadata?: { [key: string]: object; };
}
export namespace Ticket {
    export type StatusEnum = 'OPEN' | 'CLOSED';
    export const StatusEnum = {
        Open: 'OPEN' as StatusEnum,
        Closed: 'CLOSED' as StatusEnum
    };
}


