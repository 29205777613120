/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface WorkflowRun { 
    id?: string;
    infrastructure_id?: string;
    device_id?: string;
    workflow_id?: string;
    project_id?: string;
    name?: string;
    description?: string;
    status?: string;
    owner?: string;
    created?: string;
    updated?: string;
    metadata?: { [key: string]: object; };
}

