/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MeanParams { 
    /**
     * Mean aggregation.
     */
    type?: MeanParams.TypeEnum;
    /**
     * Column(s) to compute the mean.
     */
    columns: Array<string>;
}
export namespace MeanParams {
    export type TypeEnum = 'mean';
    export const TypeEnum = {
        Mean: 'mean' as TypeEnum
    };
}


