/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Resource } from './resource';
import { Workflow1AllOf } from './workflow1AllOf';
import { Project1 } from './project1';


/**
 * Object Workflow.
 */
export interface Workflow1 { 
    id: string;
    type?: Workflow1.TypeEnum;
    parent?: Project1;
}
export namespace Workflow1 {
    export type TypeEnum = 'workflow';
    export const TypeEnum = {
        Workflow: 'workflow' as TypeEnum
    };
}


