/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Filter values that are in a range/between two values.  Attributes ---------- type : RelationalOperatorType     Type of the filter. right : list     Values below and above.  Methods ------- get_value()     Get the value of the filter operator
 */
export interface BetweenFilter { 
    /**
     * Left operator for filter.
     */
    left: string;
    right: Array<string>;
    /**
     * Operator for BETWEEN filter (range).
     */
    type?: BetweenFilter.TypeEnum;
}
export namespace BetweenFilter {
    export type TypeEnum = 'between';
    export const TypeEnum = {
        Between: 'between' as TypeEnum
    };
}


