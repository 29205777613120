/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JoinParams } from './joinParams';


/**
 * Defines a task in a DAG.  Attributes ---------- id : str     Identifier of a task. params : Params     Parameters of a task.  Methods ------- accept(visitor)     Visit a task using a specified visitor. to_node()     Returns the information about the task (id and parameters). to_edge()     Gets all the dependencies of the task.
 */
export interface JoinTask { 
    /**
     * Identifier of the task.
     */
    id: string;
    /**
     * Parameters of the join task.
     */
    params: JoinParams;
    /**
     * List of all dependencies of the task.
     */
    dependency: Array<string>;
    /**
     * Type of the join task.
     */
    type?: JoinTask.TypeEnum;
}
export namespace JoinTask {
    export type TypeEnum = 'join';
    export const TypeEnum = {
        Join: 'join' as TypeEnum
    };
}


