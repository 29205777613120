/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { Endpoint } from '../model/models';
import { EndpointPage } from '../model/models';
import { LogEntry } from '../model/models';
import { Patch } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class EndpointService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Call proxy infer
     * @param xTenant 
     * @param projectId Id of the project
     * @param endpointId Id of the endpoint
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public callProxyInfer(xTenant: string, projectId: string, endpointId: string, body?: object, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<any>;
    public callProxyInfer(xTenant: string, projectId: string, endpointId: string, body?: object, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpResponse<any>>;
    public callProxyInfer(xTenant: string, projectId: string, endpointId: string, body?: object, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpEvent<any>>;
    public callProxyInfer(xTenant: string, projectId: string, endpointId: string, body?: object, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling callProxyInfer.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling callProxyInfer.');
        }
        if (endpointId === null || endpointId === undefined) {
            throw new Error('Required parameter endpointId was null or undefined when calling callProxyInfer.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.error+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/endpoints/${encodeURIComponent(String(endpointId))}/proxy/infer`,
            body,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Call proxy metadata
     * @param xTenant 
     * @param projectId Id of the project
     * @param endpointId Id of the endpoint
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public callProxyMetadata(xTenant: string, projectId: string, endpointId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<any>;
    public callProxyMetadata(xTenant: string, projectId: string, endpointId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpResponse<any>>;
    public callProxyMetadata(xTenant: string, projectId: string, endpointId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpEvent<any>>;
    public callProxyMetadata(xTenant: string, projectId: string, endpointId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling callProxyMetadata.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling callProxyMetadata.');
        }
        if (endpointId === null || endpointId === undefined) {
            throw new Error('Required parameter endpointId was null or undefined when calling callProxyMetadata.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.error+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/endpoints/${encodeURIComponent(String(endpointId))}/proxy/metadata`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create endpoint
     * @param xTenant 
     * @param projectId Id of the project
     * @param endpoint The endpoint to be created
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createEndpointForProject(xTenant: string, projectId: string, endpoint: Endpoint, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.endpoint+json'}): Observable<Endpoint>;
    public createEndpointForProject(xTenant: string, projectId: string, endpoint: Endpoint, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.endpoint+json'}): Observable<HttpResponse<Endpoint>>;
    public createEndpointForProject(xTenant: string, projectId: string, endpoint: Endpoint, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.endpoint+json'}): Observable<HttpEvent<Endpoint>>;
    public createEndpointForProject(xTenant: string, projectId: string, endpoint: Endpoint, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.endpoint+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling createEndpointForProject.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling createEndpointForProject.');
        }
        if (endpoint === null || endpoint === undefined) {
            throw new Error('Required parameter endpoint was null or undefined when calling createEndpointForProject.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.endpoint+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.graal.systems.v1.endpoint+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Endpoint>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/endpoints`,
            endpoint,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete an endpoint by an id
     * @param xTenant 
     * @param projectId Id of the project
     * @param endpointId Id of the endpoint
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteEndpointByIdAndProjectId(xTenant: string, projectId: string, endpointId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<any>;
    public deleteEndpointByIdAndProjectId(xTenant: string, projectId: string, endpointId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpResponse<any>>;
    public deleteEndpointByIdAndProjectId(xTenant: string, projectId: string, endpointId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpEvent<any>>;
    public deleteEndpointByIdAndProjectId(xTenant: string, projectId: string, endpointId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling deleteEndpointByIdAndProjectId.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling deleteEndpointByIdAndProjectId.');
        }
        if (endpointId === null || endpointId === undefined) {
            throw new Error('Required parameter endpointId was null or undefined when calling deleteEndpointByIdAndProjectId.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.error+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/endpoints/${encodeURIComponent(String(endpointId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find endpoint by Id
     * @param xTenant 
     * @param projectId Id of the project
     * @param endpointId Id of the endpoint
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findEndpointByIdAndProjectId(xTenant: string, projectId: string, endpointId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.endpoint+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<Endpoint>;
    public findEndpointByIdAndProjectId(xTenant: string, projectId: string, endpointId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.endpoint+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpResponse<Endpoint>>;
    public findEndpointByIdAndProjectId(xTenant: string, projectId: string, endpointId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.endpoint+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpEvent<Endpoint>>;
    public findEndpointByIdAndProjectId(xTenant: string, projectId: string, endpointId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.endpoint+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling findEndpointByIdAndProjectId.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling findEndpointByIdAndProjectId.');
        }
        if (endpointId === null || endpointId === undefined) {
            throw new Error('Required parameter endpointId was null or undefined when calling findEndpointByIdAndProjectId.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.endpoint+json',
                'application/vnd.graal.systems.v1.error+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Endpoint>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/endpoints/${encodeURIComponent(String(endpointId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve all endpoints
     * @param xTenant 
     * @param projectId Id of the project
     * @param page 
     * @param size 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findEndpointsByProjectId(xTenant: string, projectId: string, page?: number, size?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.endpoint+json'}): Observable<EndpointPage>;
    public findEndpointsByProjectId(xTenant: string, projectId: string, page?: number, size?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.endpoint+json'}): Observable<HttpResponse<EndpointPage>>;
    public findEndpointsByProjectId(xTenant: string, projectId: string, page?: number, size?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.endpoint+json'}): Observable<HttpEvent<EndpointPage>>;
    public findEndpointsByProjectId(xTenant: string, projectId: string, page?: number, size?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.endpoint+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling findEndpointsByProjectId.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling findEndpointsByProjectId.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (size !== undefined && size !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>size, 'size');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.endpoint+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<EndpointPage>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/endpoints`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get logs
     * @param xTenant 
     * @param projectId Id of the project
     * @param endpointId Id of the endpoint
     * @param cursor The cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLogsForEndpoint(xTenant: string, projectId: string, endpointId: string, cursor?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.log+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<Array<LogEntry>>;
    public getLogsForEndpoint(xTenant: string, projectId: string, endpointId: string, cursor?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.log+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpResponse<Array<LogEntry>>>;
    public getLogsForEndpoint(xTenant: string, projectId: string, endpointId: string, cursor?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.log+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpEvent<Array<LogEntry>>>;
    public getLogsForEndpoint(xTenant: string, projectId: string, endpointId: string, cursor?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.log+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getLogsForEndpoint.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getLogsForEndpoint.');
        }
        if (endpointId === null || endpointId === undefined) {
            throw new Error('Required parameter endpointId was null or undefined when calling getLogsForEndpoint.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (cursor !== undefined && cursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cursor, 'cursor');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.log+json',
                'application/vnd.graal.systems.v1.error+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<LogEntry>>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/endpoints/${encodeURIComponent(String(endpointId))}/logs`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an endpoint
     * @param xTenant 
     * @param projectId Id of the project
     * @param endpointId Id of the endpoint
     * @param patch The patch
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateEndpointByIdAndProjectId(xTenant: string, projectId: string, endpointId: string, patch: Array<Patch>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.endpoint+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<Endpoint>;
    public updateEndpointByIdAndProjectId(xTenant: string, projectId: string, endpointId: string, patch: Array<Patch>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.endpoint+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpResponse<Endpoint>>;
    public updateEndpointByIdAndProjectId(xTenant: string, projectId: string, endpointId: string, patch: Array<Patch>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.endpoint+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpEvent<Endpoint>>;
    public updateEndpointByIdAndProjectId(xTenant: string, projectId: string, endpointId: string, patch: Array<Patch>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.endpoint+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling updateEndpointByIdAndProjectId.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateEndpointByIdAndProjectId.');
        }
        if (endpointId === null || endpointId === undefined) {
            throw new Error('Required parameter endpointId was null or undefined when calling updateEndpointByIdAndProjectId.');
        }
        if (patch === null || patch === undefined) {
            throw new Error('Required parameter patch was null or undefined when calling updateEndpointByIdAndProjectId.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.endpoint+json',
                'application/vnd.graal.systems.v1.error+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json;charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<Endpoint>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/endpoints/${encodeURIComponent(String(endpointId))}`,
            patch,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
