/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface JoinParams { 
    /**
     * List of dictionaries where the \'left\' key represents the         name of the column in the left DataFrame and the \'right\' key represents         the name of the column to join with the right DataFrame.
     */
    columns: Array<{ [key: string]: string; }>;
    /**
     * Join two tables (left, right, inner, outer)
     */
    join_type: string;
}

