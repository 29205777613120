/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { Activity } from '../model/models';
import { Comment } from '../model/models';
import { CommentActivity } from '../model/models';
import { HTTPValidationError } from '../model/models';
import { Reaction } from '../model/models';
import { ReactionCount } from '../model/models';
import { ResourceType } from '../model/models';
import { TimelineCountResponse } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ActivityService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create a new activity
     * An user can create a new activity in the platform. Activities represent events or tasks that users can participate in. Each activity may have a name, description, date and time.
     * @param xTenant 
     * @param activity 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createActivity(xTenant: string, activity: Activity, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Activity>;
    public createActivity(xTenant: string, activity: Activity, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Activity>>;
    public createActivity(xTenant: string, activity: Activity, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Activity>>;
    public createActivity(xTenant: string, activity: Activity, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling createActivity.');
        }
        if (activity === null || activity === undefined) {
            throw new Error('Required parameter activity was null or undefined when calling createActivity.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Activity>(`${this.configuration.basePath}/activities`,
            activity,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Insert a user comment for a given activity
     * Create a new comment for an activity.
     * @param xTenant 
     * @param activityId 
     * @param comment 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCommentForActivity(xTenant: string, activityId: string, comment: Comment, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<CommentActivity>>;
    public createCommentForActivity(xTenant: string, activityId: string, comment: Comment, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<CommentActivity>>>;
    public createCommentForActivity(xTenant: string, activityId: string, comment: Comment, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<CommentActivity>>>;
    public createCommentForActivity(xTenant: string, activityId: string, comment: Comment, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling createCommentForActivity.');
        }
        if (activityId === null || activityId === undefined) {
            throw new Error('Required parameter activityId was null or undefined when calling createCommentForActivity.');
        }
        if (comment === null || comment === undefined) {
            throw new Error('Required parameter comment was null or undefined when calling createCommentForActivity.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<CommentActivity>>(`${this.configuration.basePath}/activities/${encodeURIComponent(String(activityId))}/comments`,
            comment,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Insert the reaction of an user to a given activity
     * Insert a user\&#39;s reaction to an activity. Each reaction increments the reaction counter and adds the user to the list of users who used that reaction.
     * @param xTenant 
     * @param activityId 
     * @param reaction 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createReactionForActivity(xTenant: string, activityId: string, reaction: Reaction, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ReactionCount>>;
    public createReactionForActivity(xTenant: string, activityId: string, reaction: Reaction, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ReactionCount>>>;
    public createReactionForActivity(xTenant: string, activityId: string, reaction: Reaction, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ReactionCount>>>;
    public createReactionForActivity(xTenant: string, activityId: string, reaction: Reaction, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling createReactionForActivity.');
        }
        if (activityId === null || activityId === undefined) {
            throw new Error('Required parameter activityId was null or undefined when calling createReactionForActivity.');
        }
        if (reaction === null || reaction === undefined) {
            throw new Error('Required parameter reaction was null or undefined when calling createReactionForActivity.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<ReactionCount>>(`${this.configuration.basePath}/activities/${encodeURIComponent(String(activityId))}/reactions`,
            reaction,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the comment of an user for a given activity
     * Delete an user\&#39;s comment to an activity.
     * @param xTenant 
     * @param activityId 
     * @param commentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCommentFromActivity(xTenant: string, activityId: string, commentId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteCommentFromActivity(xTenant: string, activityId: string, commentId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteCommentFromActivity(xTenant: string, activityId: string, commentId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteCommentFromActivity(xTenant: string, activityId: string, commentId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling deleteCommentFromActivity.');
        }
        if (activityId === null || activityId === undefined) {
            throw new Error('Required parameter activityId was null or undefined when calling deleteCommentFromActivity.');
        }
        if (commentId === null || commentId === undefined) {
            throw new Error('Required parameter commentId was null or undefined when calling deleteCommentFromActivity.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/activities/${encodeURIComponent(String(activityId))}/comments/${encodeURIComponent(String(commentId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete users\&#39; reactions for a given activity
     * Delete a user\&#39;s reaction to an activity. Each reaction decrements (or deletes) the reaction counter and removes the user from the list of users who have used that reaction.
     * @param xTenant 
     * @param activityId 
     * @param reactionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteReactionFromActivity(xTenant: string, activityId: string, reactionId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteReactionFromActivity(xTenant: string, activityId: string, reactionId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteReactionFromActivity(xTenant: string, activityId: string, reactionId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteReactionFromActivity(xTenant: string, activityId: string, reactionId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling deleteReactionFromActivity.');
        }
        if (activityId === null || activityId === undefined) {
            throw new Error('Required parameter activityId was null or undefined when calling deleteReactionFromActivity.');
        }
        if (reactionId === null || reactionId === undefined) {
            throw new Error('Required parameter reactionId was null or undefined when calling deleteReactionFromActivity.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/activities/${encodeURIComponent(String(activityId))}/reactions/${encodeURIComponent(String(reactionId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all activities from timeline
     * Return timeline for specific resources.
     * @param xTenant 
     * @param resourceId 
     * @param resourceType 
     * @param xCursor 
     * @param activityType 
     * @param batchSize 
     * @param aggregated 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActivitiesFromTimeline(xTenant: string, resourceId: string, resourceType: ResourceType, xCursor?: string, activityType?: string, batchSize?: number, aggregated?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Activity>>;
    public getActivitiesFromTimeline(xTenant: string, resourceId: string, resourceType: ResourceType, xCursor?: string, activityType?: string, batchSize?: number, aggregated?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Activity>>>;
    public getActivitiesFromTimeline(xTenant: string, resourceId: string, resourceType: ResourceType, xCursor?: string, activityType?: string, batchSize?: number, aggregated?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Activity>>>;
    public getActivitiesFromTimeline(xTenant: string, resourceId: string, resourceType: ResourceType, xCursor?: string, activityType?: string, batchSize?: number, aggregated?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getActivitiesFromTimeline.');
        }
        if (resourceId === null || resourceId === undefined) {
            throw new Error('Required parameter resourceId was null or undefined when calling getActivitiesFromTimeline.');
        }
        if (resourceType === null || resourceType === undefined) {
            throw new Error('Required parameter resourceType was null or undefined when calling getActivitiesFromTimeline.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (resourceId !== undefined && resourceId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resourceId, 'resource_id');
        }
        if (resourceType !== undefined && resourceType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resourceType, 'resource_type');
        }
        if (activityType !== undefined && activityType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>activityType, 'activity_type');
        }
        if (batchSize !== undefined && batchSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>batchSize, 'batch_size');
        }
        if (aggregated !== undefined && aggregated !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>aggregated, 'aggregated');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }
        if (xCursor !== undefined && xCursor !== null) {
            headers = headers.set('x-cursor', String(xCursor));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<Activity>>(`${this.configuration.basePath}/activities`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a count of all the activities for a resource
     * For each resource (within a defined tenant), retrieve the number of activities per day.
     * @param xTenant 
     * @param resourceId 
     * @param resourceType 
     * @param year 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActivitiesSummary(xTenant: string, resourceId: string, resourceType: ResourceType, year?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TimelineCountResponse>;
    public getActivitiesSummary(xTenant: string, resourceId: string, resourceType: ResourceType, year?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TimelineCountResponse>>;
    public getActivitiesSummary(xTenant: string, resourceId: string, resourceType: ResourceType, year?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TimelineCountResponse>>;
    public getActivitiesSummary(xTenant: string, resourceId: string, resourceType: ResourceType, year?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getActivitiesSummary.');
        }
        if (resourceId === null || resourceId === undefined) {
            throw new Error('Required parameter resourceId was null or undefined when calling getActivitiesSummary.');
        }
        if (resourceType === null || resourceType === undefined) {
            throw new Error('Required parameter resourceType was null or undefined when calling getActivitiesSummary.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (resourceType !== undefined && resourceType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resourceType, 'resource_type');
        }
        if (year !== undefined && year !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>year, 'year');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<TimelineCountResponse>(`${this.configuration.basePath}/activities/${encodeURIComponent(String(resourceId))}/summary`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all the comments for a given activity
     * For each activity (within a defined tenant), retrieve all the comment information. This information is made up of the comments of the activity with the users who wrote the comments.
     * @param xTenant 
     * @param activityId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCommentsFromActivity(xTenant: string, activityId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<CommentActivity>>;
    public getCommentsFromActivity(xTenant: string, activityId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<CommentActivity>>>;
    public getCommentsFromActivity(xTenant: string, activityId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<CommentActivity>>>;
    public getCommentsFromActivity(xTenant: string, activityId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getCommentsFromActivity.');
        }
        if (activityId === null || activityId === undefined) {
            throw new Error('Required parameter activityId was null or undefined when calling getCommentsFromActivity.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<CommentActivity>>(`${this.configuration.basePath}/activities/${encodeURIComponent(String(activityId))}/comments`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of defined years for a resource
     * For each resource (within a defined tenant), get a list of defined years.
     * @param xTenant 
     * @param resourceId 
     * @param resourceType 
     * @param parentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCreationDate(xTenant: string, resourceId: string, resourceType: ResourceType, parentId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<number>>;
    public getCreationDate(xTenant: string, resourceId: string, resourceType: ResourceType, parentId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<number>>>;
    public getCreationDate(xTenant: string, resourceId: string, resourceType: ResourceType, parentId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<number>>>;
    public getCreationDate(xTenant: string, resourceId: string, resourceType: ResourceType, parentId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getCreationDate.');
        }
        if (resourceId === null || resourceId === undefined) {
            throw new Error('Required parameter resourceId was null or undefined when calling getCreationDate.');
        }
        if (resourceType === null || resourceType === undefined) {
            throw new Error('Required parameter resourceType was null or undefined when calling getCreationDate.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (resourceType !== undefined && resourceType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resourceType, 'resource_type');
        }
        if (parentId !== undefined && parentId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>parentId, 'parent_id');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<number>>(`${this.configuration.basePath}/resources/${encodeURIComponent(String(resourceId))}/years`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all user reactions for a given activity
     * For each activity (within a defined tenant), retrieve all the reaction information. This information is made up of the names of the reactions and the list of the users who have used the given reaction.
     * @param xTenant 
     * @param activityId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReactionsFromActivity(xTenant: string, activityId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Reaction>>;
    public getReactionsFromActivity(xTenant: string, activityId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Reaction>>>;
    public getReactionsFromActivity(xTenant: string, activityId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Reaction>>>;
    public getReactionsFromActivity(xTenant: string, activityId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getReactionsFromActivity.');
        }
        if (activityId === null || activityId === undefined) {
            throw new Error('Required parameter activityId was null or undefined when calling getReactionsFromActivity.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<Reaction>>(`${this.configuration.basePath}/activities/${encodeURIComponent(String(activityId))}/reactions`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the comment of an user for a given activity
     * Update an user\&#39;s comment to an activity.
     * @param xTenant 
     * @param activityId 
     * @param commentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCommentForActivity(xTenant: string, activityId: string, commentId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<CommentActivity>>;
    public updateCommentForActivity(xTenant: string, activityId: string, commentId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<CommentActivity>>>;
    public updateCommentForActivity(xTenant: string, activityId: string, commentId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<CommentActivity>>>;
    public updateCommentForActivity(xTenant: string, activityId: string, commentId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling updateCommentForActivity.');
        }
        if (activityId === null || activityId === undefined) {
            throw new Error('Required parameter activityId was null or undefined when calling updateCommentForActivity.');
        }
        if (commentId === null || commentId === undefined) {
            throw new Error('Required parameter commentId was null or undefined when calling updateCommentForActivity.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<Array<CommentActivity>>(`${this.configuration.basePath}/activities/${encodeURIComponent(String(activityId))}/comments/${encodeURIComponent(String(commentId))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
