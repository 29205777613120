/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SaveMode } from './saveMode';


export interface ParquetWriter { 
    /**
     * How to partition the dataset.
     */
    partition_by?: Array<string>;
    /**
     * How to write the file (append, overwrite)
     */
    mode?: SaveMode;
    /**
     * Type of on-the-fly compression to use.
     */
    compression?: string;
    /**
     * Define file type for PARQUET.
     */
    type?: ParquetWriter.TypeEnum;
    /**
     * Path to PARQUET file.
     */
    path: string;
}
export namespace ParquetWriter {
    export type TypeEnum = 'parquet';
    export const TypeEnum = {
        Parquet: 'parquet' as TypeEnum
    };
}


