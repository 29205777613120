/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { S3ConnectorOptions } from './s3ConnectorOptions';


/**
 * Connect to a S3 bucket.  Attributes ---------- type : ConnectorType     S3 connector type. options : S3ConnectorOptions     Options to connect to a S3 bucket (i.e. credentials). _prefix : str     Prefix of the S3 bucket.
 */
export interface S3Connector { 
    /**
     * Connection to a S3 bucket.
     */
    type?: S3Connector.TypeEnum;
    /**
     * Options to connect to a S3 bucket such as credentials.
     */
    options: S3ConnectorOptions;
}
export namespace S3Connector {
    export type TypeEnum = 's3';
    export const TypeEnum = {
        S3: 's3' as TypeEnum
    };
}


