/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LocalConnector } from './localConnector';
import { JoblibReader } from './joblibReader';
import { PickleReader } from './pickleReader';
import { S3Connector } from './s3Connector';


export interface LoadModelParams { 
    /**
     * Type of object model.
     */
    type?: LoadModelParams.TypeEnum;
    /**
     * Type of connector
     */
    connector: S3Connector | LocalConnector;
    /**
     * Options for reading a file (header, file path, separator,         delimiter, ...).
     */
    object: PickleReader | JoblibReader;
}
export namespace LoadModelParams {
    export type TypeEnum = 'model';
    export const TypeEnum = {
        Model: 'model' as TypeEnum
    };
}


