/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Options to connect to an Azure bucket.  Attributes ---------- storage_name : str     Name of the Azure storage. sas_token : str     SAS token to connect to Azure. container : str     Name of the container.
 */
export interface AzureConnectorOptions { 
    /**
     * Name of the Azure Blob Storage.
     */
    storage_name: string;
    /**
     * SAS token for Azure Blob Storage connection.
     */
    sas_token: string;
    /**
     * Name of the Azure Blob Storage container.
     */
    container: string;
}

