/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User1 } from './user1';
import { Project1 } from './project1';


export interface UserActivityAllOf { 
    type?: UserActivityAllOf.TypeEnum;
    actor: User1;
    target: User1 | Project1;
    verb: string;
}
export namespace UserActivityAllOf {
    export type TypeEnum = 'user';
    export const TypeEnum = {
        User: 'user' as TypeEnum
    };
}


