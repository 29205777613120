/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Resource } from './resource';
import { Project1AllOf } from './project1AllOf';


/**
 * Object Project.
 */
export interface Project1 { 
    id: string;
    type?: Project1.TypeEnum;
}
export namespace Project1 {
    export type TypeEnum = 'project';
    export const TypeEnum = {
        Project: 'project' as TypeEnum
    };
}


