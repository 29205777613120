/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Project1 } from './project1';


export interface Job1AllOf { 
    type?: Job1AllOf.TypeEnum;
    parent?: Project1;
}
export namespace Job1AllOf {
    export type TypeEnum = 'job';
    export const TypeEnum = {
        Job: 'job' as TypeEnum
    };
}


