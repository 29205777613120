/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ExperimentRun { 
    id?: string;
    name?: string;
    description?: string;
    owner?: string;
    start?: string;
    update?: string;
    end?: string;
    experiment_id?: string;
    project_id?: string;
    artifact_uri?: string;
    status?: string;
    metadata?: { [key: string]: object; };
    labels?: { [key: string]: string; };
}

