/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface User1AllOf { 
    type?: User1AllOf.TypeEnum;
}
export namespace User1AllOf {
    export type TypeEnum = 'user';
    export const TypeEnum = {
        User: 'user' as TypeEnum
    };
}


