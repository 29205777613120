/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MaxParams { 
    /**
     * Maximum aggregation.
     */
    type?: MaxParams.TypeEnum;
    /**
     * Column(s) to compute the maximum.
     */
    columns: Array<string>;
}
export namespace MaxParams {
    export type TypeEnum = 'max';
    export const TypeEnum = {
        Max: 'max' as TypeEnum
    };
}


