/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User1 } from './user1';
import { Project1 } from './project1';


export interface ProjectActivityAllOf { 
    type?: ProjectActivityAllOf.TypeEnum;
    actor: User1;
    target: Project1;
    verb: string;
}
export namespace ProjectActivityAllOf {
    export type TypeEnum = 'project';
    export const TypeEnum = {
        Project: 'project' as TypeEnum
    };
}


