/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { Follow } from '../model/models';
import { HTTPValidationError } from '../model/models';
import { Project1 } from '../model/models';
import { User1 } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class FollowService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create Catalog Followers
     * Create a new follow relationship between a catalog and an user.
     * @param xTenant 
     * @param catalogId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCatalogFollower(xTenant: string, catalogId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Follow>;
    public createCatalogFollower(xTenant: string, catalogId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Follow>>;
    public createCatalogFollower(xTenant: string, catalogId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Follow>>;
    public createCatalogFollower(xTenant: string, catalogId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling createCatalogFollower.');
        }
        if (catalogId === null || catalogId === undefined) {
            throw new Error('Required parameter catalogId was null or undefined when calling createCatalogFollower.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Follow>(`${this.configuration.basePath}/catalogs/${encodeURIComponent(String(catalogId))}/followers`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Database Followers
     * Create a new follow relationship between a database and an user.
     * @param xTenant 
     * @param databaseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDatabaseFollower(xTenant: string, databaseId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Follow>;
    public createDatabaseFollower(xTenant: string, databaseId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Follow>>;
    public createDatabaseFollower(xTenant: string, databaseId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Follow>>;
    public createDatabaseFollower(xTenant: string, databaseId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling createDatabaseFollower.');
        }
        if (databaseId === null || databaseId === undefined) {
            throw new Error('Required parameter databaseId was null or undefined when calling createDatabaseFollower.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Follow>(`${this.configuration.basePath}/databases/${encodeURIComponent(String(databaseId))}/followers`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Endpoint Followers
     * Create a new follow relationship between a endpoint and an user.
     * @param xTenant 
     * @param xUser 
     * @param xInternal 
     * @param endpointId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createEndpointFollower(xTenant: string, xUser: string, xInternal: boolean, endpointId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Follow>;
    public createEndpointFollower(xTenant: string, xUser: string, xInternal: boolean, endpointId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Follow>>;
    public createEndpointFollower(xTenant: string, xUser: string, xInternal: boolean, endpointId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Follow>>;
    public createEndpointFollower(xTenant: string, xUser: string, xInternal: boolean, endpointId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling createEndpointFollower.');
        }
        if (xUser === null || xUser === undefined) {
            throw new Error('Required parameter xUser was null or undefined when calling createEndpointFollower.');
        }
        if (xInternal === null || xInternal === undefined) {
            throw new Error('Required parameter xInternal was null or undefined when calling createEndpointFollower.');
        }
        if (endpointId === null || endpointId === undefined) {
            throw new Error('Required parameter endpointId was null or undefined when calling createEndpointFollower.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }
        if (xUser !== undefined && xUser !== null) {
            headers = headers.set('x-user', String(xUser));
        }
        if (xInternal !== undefined && xInternal !== null) {
            headers = headers.set('x-internal', String(xInternal));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Follow>(`${this.configuration.basePath}/endpoints/${encodeURIComponent(String(endpointId))}/followers`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Experiment Followers
     * Create a new follow relationship between a experiment and an user.
     * @param xTenant 
     * @param xUser 
     * @param xInternal 
     * @param experimentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createExperimentFollower(xTenant: string, xUser: string, xInternal: boolean, experimentId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Follow>;
    public createExperimentFollower(xTenant: string, xUser: string, xInternal: boolean, experimentId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Follow>>;
    public createExperimentFollower(xTenant: string, xUser: string, xInternal: boolean, experimentId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Follow>>;
    public createExperimentFollower(xTenant: string, xUser: string, xInternal: boolean, experimentId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling createExperimentFollower.');
        }
        if (xUser === null || xUser === undefined) {
            throw new Error('Required parameter xUser was null or undefined when calling createExperimentFollower.');
        }
        if (xInternal === null || xInternal === undefined) {
            throw new Error('Required parameter xInternal was null or undefined when calling createExperimentFollower.');
        }
        if (experimentId === null || experimentId === undefined) {
            throw new Error('Required parameter experimentId was null or undefined when calling createExperimentFollower.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }
        if (xUser !== undefined && xUser !== null) {
            headers = headers.set('x-user', String(xUser));
        }
        if (xInternal !== undefined && xInternal !== null) {
            headers = headers.set('x-internal', String(xInternal));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Follow>(`${this.configuration.basePath}/experiments/${encodeURIComponent(String(experimentId))}/followers`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Job Followers
     * Create a new follow relationship between a job and an user.
     * @param xTenant 
     * @param jobId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createJobFollower(xTenant: string, jobId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Follow>;
    public createJobFollower(xTenant: string, jobId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Follow>>;
    public createJobFollower(xTenant: string, jobId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Follow>>;
    public createJobFollower(xTenant: string, jobId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling createJobFollower.');
        }
        if (jobId === null || jobId === undefined) {
            throw new Error('Required parameter jobId was null or undefined when calling createJobFollower.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Follow>(`${this.configuration.basePath}/jobs/${encodeURIComponent(String(jobId))}/followers`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Project Followers
     * Create a new follow relationship between a project and an user.
     * @param xTenant 
     * @param projectId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProjectFollower(xTenant: string, projectId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Follow>;
    public createProjectFollower(xTenant: string, projectId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Follow>>;
    public createProjectFollower(xTenant: string, projectId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Follow>>;
    public createProjectFollower(xTenant: string, projectId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling createProjectFollower.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling createProjectFollower.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Follow>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/followers`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Table Followers
     * Create a new follow relationship between a table and an user.
     * @param xTenant 
     * @param tableId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTableFollower(xTenant: string, tableId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Follow>;
    public createTableFollower(xTenant: string, tableId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Follow>>;
    public createTableFollower(xTenant: string, tableId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Follow>>;
    public createTableFollower(xTenant: string, tableId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling createTableFollower.');
        }
        if (tableId === null || tableId === undefined) {
            throw new Error('Required parameter tableId was null or undefined when calling createTableFollower.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Follow>(`${this.configuration.basePath}/tables/${encodeURIComponent(String(tableId))}/followers`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create User Followers
     * Create a new follow relationship between a two users.
     * @param xTenant 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUserFollower(xTenant: string, userId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Follow>;
    public createUserFollower(xTenant: string, userId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Follow>>;
    public createUserFollower(xTenant: string, userId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Follow>>;
    public createUserFollower(xTenant: string, userId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling createUserFollower.');
        }
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling createUserFollower.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Follow>(`${this.configuration.basePath}/users/${encodeURIComponent(String(userId))}/followers`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Workflow Followers
     * Create a new follow relationship between a workflow and an user.
     * @param xTenant 
     * @param workflowId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createWorkflowFollower(xTenant: string, workflowId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Follow>;
    public createWorkflowFollower(xTenant: string, workflowId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Follow>>;
    public createWorkflowFollower(xTenant: string, workflowId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Follow>>;
    public createWorkflowFollower(xTenant: string, workflowId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling createWorkflowFollower.');
        }
        if (workflowId === null || workflowId === undefined) {
            throw new Error('Required parameter workflowId was null or undefined when calling createWorkflowFollower.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Follow>(`${this.configuration.basePath}/workflows/${encodeURIComponent(String(workflowId))}/followers`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Following Catalog
     * Delete the follow relationship between a catalog and an user.
     * @param xTenant 
     * @param catalogId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFollowingCatalog(xTenant: string, catalogId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteFollowingCatalog(xTenant: string, catalogId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteFollowingCatalog(xTenant: string, catalogId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteFollowingCatalog(xTenant: string, catalogId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling deleteFollowingCatalog.');
        }
        if (catalogId === null || catalogId === undefined) {
            throw new Error('Required parameter catalogId was null or undefined when calling deleteFollowingCatalog.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/following/catalogs/${encodeURIComponent(String(catalogId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Following Database
     * Delete the follow relationship between a database and an user.
     * @param xTenant 
     * @param databaseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFollowingDatabase(xTenant: string, databaseId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteFollowingDatabase(xTenant: string, databaseId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteFollowingDatabase(xTenant: string, databaseId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteFollowingDatabase(xTenant: string, databaseId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling deleteFollowingDatabase.');
        }
        if (databaseId === null || databaseId === undefined) {
            throw new Error('Required parameter databaseId was null or undefined when calling deleteFollowingDatabase.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/following/databases/${encodeURIComponent(String(databaseId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Following Endpoint
     * Delete the follow relationship between a endpoint and an user.
     * @param xTenant 
     * @param xUser 
     * @param endpointId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFollowingEndpoint(xTenant: string, xUser: string, endpointId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteFollowingEndpoint(xTenant: string, xUser: string, endpointId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteFollowingEndpoint(xTenant: string, xUser: string, endpointId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteFollowingEndpoint(xTenant: string, xUser: string, endpointId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling deleteFollowingEndpoint.');
        }
        if (xUser === null || xUser === undefined) {
            throw new Error('Required parameter xUser was null or undefined when calling deleteFollowingEndpoint.');
        }
        if (endpointId === null || endpointId === undefined) {
            throw new Error('Required parameter endpointId was null or undefined when calling deleteFollowingEndpoint.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }
        if (xUser !== undefined && xUser !== null) {
            headers = headers.set('x-user', String(xUser));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/following/endpoints/${encodeURIComponent(String(endpointId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Following Experiment
     * Delete the follow relationship between a experiment and an user.
     * @param xTenant 
     * @param xUser 
     * @param experimentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFollowingExperiment(xTenant: string, xUser: string, experimentId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteFollowingExperiment(xTenant: string, xUser: string, experimentId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteFollowingExperiment(xTenant: string, xUser: string, experimentId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteFollowingExperiment(xTenant: string, xUser: string, experimentId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling deleteFollowingExperiment.');
        }
        if (xUser === null || xUser === undefined) {
            throw new Error('Required parameter xUser was null or undefined when calling deleteFollowingExperiment.');
        }
        if (experimentId === null || experimentId === undefined) {
            throw new Error('Required parameter experimentId was null or undefined when calling deleteFollowingExperiment.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }
        if (xUser !== undefined && xUser !== null) {
            headers = headers.set('x-user', String(xUser));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/following/experiments/${encodeURIComponent(String(experimentId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Following Job
     * Delete the follow relationship between a job and an user.
     * @param xTenant 
     * @param jobId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFollowingJob(xTenant: string, jobId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteFollowingJob(xTenant: string, jobId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteFollowingJob(xTenant: string, jobId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteFollowingJob(xTenant: string, jobId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling deleteFollowingJob.');
        }
        if (jobId === null || jobId === undefined) {
            throw new Error('Required parameter jobId was null or undefined when calling deleteFollowingJob.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/following/jobs/${encodeURIComponent(String(jobId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Following Project
     * Delete the follow relationship between a project and an user.
     * @param xTenant 
     * @param projectId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFollowingProject(xTenant: string, projectId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteFollowingProject(xTenant: string, projectId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteFollowingProject(xTenant: string, projectId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteFollowingProject(xTenant: string, projectId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling deleteFollowingProject.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling deleteFollowingProject.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/following/projects/${encodeURIComponent(String(projectId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Following Table
     * Delete the follow relationship between a table and an user.
     * @param xTenant 
     * @param tableId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFollowingTable(xTenant: string, tableId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteFollowingTable(xTenant: string, tableId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteFollowingTable(xTenant: string, tableId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteFollowingTable(xTenant: string, tableId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling deleteFollowingTable.');
        }
        if (tableId === null || tableId === undefined) {
            throw new Error('Required parameter tableId was null or undefined when calling deleteFollowingTable.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/following/tables/${encodeURIComponent(String(tableId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Following User
     * Delete the follow relationship between two users.
     * @param xTenant 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFollowingUser(xTenant: string, userId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteFollowingUser(xTenant: string, userId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteFollowingUser(xTenant: string, userId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteFollowingUser(xTenant: string, userId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling deleteFollowingUser.');
        }
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling deleteFollowingUser.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/following/users/${encodeURIComponent(String(userId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Following User
     * Delete the follow relationship between a workflow and a user.
     * @param xTenant 
     * @param workflowId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFollowingWorkflow(xTenant: string, workflowId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteFollowingWorkflow(xTenant: string, workflowId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteFollowingWorkflow(xTenant: string, workflowId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteFollowingWorkflow(xTenant: string, workflowId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling deleteFollowingWorkflow.');
        }
        if (workflowId === null || workflowId === undefined) {
            throw new Error('Required parameter workflowId was null or undefined when calling deleteFollowingWorkflow.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/following/workflows/${encodeURIComponent(String(workflowId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Catalog Followers
     * Route to GET all users following a given catalog.
     * @param xTenant 
     * @param catalogId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCatalogFollowers(xTenant: string, catalogId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<User1>>;
    public getCatalogFollowers(xTenant: string, catalogId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<User1>>>;
    public getCatalogFollowers(xTenant: string, catalogId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<User1>>>;
    public getCatalogFollowers(xTenant: string, catalogId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getCatalogFollowers.');
        }
        if (catalogId === null || catalogId === undefined) {
            throw new Error('Required parameter catalogId was null or undefined when calling getCatalogFollowers.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<User1>>(`${this.configuration.basePath}/catalogs/${encodeURIComponent(String(catalogId))}/followers`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Database Followers
     * Route to GET all users following a given database.
     * @param xTenant 
     * @param databaseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDatabaseFollowers(xTenant: string, databaseId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<User1>>;
    public getDatabaseFollowers(xTenant: string, databaseId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<User1>>>;
    public getDatabaseFollowers(xTenant: string, databaseId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<User1>>>;
    public getDatabaseFollowers(xTenant: string, databaseId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getDatabaseFollowers.');
        }
        if (databaseId === null || databaseId === undefined) {
            throw new Error('Required parameter databaseId was null or undefined when calling getDatabaseFollowers.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<User1>>(`${this.configuration.basePath}/databases/${encodeURIComponent(String(databaseId))}/followers`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Endpoint Followers
     * Route to GET all users following a given endpoint.
     * @param xTenant 
     * @param xUser 
     * @param xInternal 
     * @param endpointId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEndpointFollowers(xTenant: string, xUser: string, xInternal: boolean, endpointId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<User1>>;
    public getEndpointFollowers(xTenant: string, xUser: string, xInternal: boolean, endpointId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<User1>>>;
    public getEndpointFollowers(xTenant: string, xUser: string, xInternal: boolean, endpointId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<User1>>>;
    public getEndpointFollowers(xTenant: string, xUser: string, xInternal: boolean, endpointId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getEndpointFollowers.');
        }
        if (xUser === null || xUser === undefined) {
            throw new Error('Required parameter xUser was null or undefined when calling getEndpointFollowers.');
        }
        if (xInternal === null || xInternal === undefined) {
            throw new Error('Required parameter xInternal was null or undefined when calling getEndpointFollowers.');
        }
        if (endpointId === null || endpointId === undefined) {
            throw new Error('Required parameter endpointId was null or undefined when calling getEndpointFollowers.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }
        if (xUser !== undefined && xUser !== null) {
            headers = headers.set('X-User', String(xUser));
        }
        if (xInternal !== undefined && xInternal !== null) {
            headers = headers.set('x-internal', String(xInternal));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<User1>>(`${this.configuration.basePath}/endpoints/${encodeURIComponent(String(endpointId))}/followers`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Experiment Followers
     * Route to GET all users following a given experiment.
     * @param xTenant 
     * @param xUser 
     * @param xInternal 
     * @param experimentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getExperimentFollowers(xTenant: string, xUser: string, xInternal: boolean, experimentId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<User1>>;
    public getExperimentFollowers(xTenant: string, xUser: string, xInternal: boolean, experimentId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<User1>>>;
    public getExperimentFollowers(xTenant: string, xUser: string, xInternal: boolean, experimentId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<User1>>>;
    public getExperimentFollowers(xTenant: string, xUser: string, xInternal: boolean, experimentId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getExperimentFollowers.');
        }
        if (xUser === null || xUser === undefined) {
            throw new Error('Required parameter xUser was null or undefined when calling getExperimentFollowers.');
        }
        if (xInternal === null || xInternal === undefined) {
            throw new Error('Required parameter xInternal was null or undefined when calling getExperimentFollowers.');
        }
        if (experimentId === null || experimentId === undefined) {
            throw new Error('Required parameter experimentId was null or undefined when calling getExperimentFollowers.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }
        if (xUser !== undefined && xUser !== null) {
            headers = headers.set('X-User', String(xUser));
        }
        if (xInternal !== undefined && xInternal !== null) {
            headers = headers.set('x-internal', String(xInternal));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<User1>>(`${this.configuration.basePath}/experiments/${encodeURIComponent(String(experimentId))}/followers`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Job Followers
     * Route to GET all users following a given job.
     * @param xTenant 
     * @param jobId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getJobFollowers(xTenant: string, jobId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<User1>>;
    public getJobFollowers(xTenant: string, jobId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<User1>>>;
    public getJobFollowers(xTenant: string, jobId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<User1>>>;
    public getJobFollowers(xTenant: string, jobId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getJobFollowers.');
        }
        if (jobId === null || jobId === undefined) {
            throw new Error('Required parameter jobId was null or undefined when calling getJobFollowers.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<User1>>(`${this.configuration.basePath}/jobs/${encodeURIComponent(String(jobId))}/followers`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Project Followers
     * Route to GET all users following a given project.
     * @param xTenant 
     * @param projectId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectFollowers(xTenant: string, projectId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<User1>>;
    public getProjectFollowers(xTenant: string, projectId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<User1>>>;
    public getProjectFollowers(xTenant: string, projectId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<User1>>>;
    public getProjectFollowers(xTenant: string, projectId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getProjectFollowers.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectFollowers.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<User1>>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/followers`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Table Followers
     * Route to GET all users following a given table.
     * @param xTenant 
     * @param tableId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTableFollowers(xTenant: string, tableId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<User1>>;
    public getTableFollowers(xTenant: string, tableId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<User1>>>;
    public getTableFollowers(xTenant: string, tableId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<User1>>>;
    public getTableFollowers(xTenant: string, tableId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getTableFollowers.');
        }
        if (tableId === null || tableId === undefined) {
            throw new Error('Required parameter tableId was null or undefined when calling getTableFollowers.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<User1>>(`${this.configuration.basePath}/tables/${encodeURIComponent(String(tableId))}/followers`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get User Followers
     * Route to GET all followers of a given user.
     * @param xTenant 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserFollowers(xTenant: string, userId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<User1>>;
    public getUserFollowers(xTenant: string, userId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<User1>>>;
    public getUserFollowers(xTenant: string, userId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<User1>>>;
    public getUserFollowers(xTenant: string, userId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getUserFollowers.');
        }
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getUserFollowers.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<User1>>(`${this.configuration.basePath}/users/${encodeURIComponent(String(userId))}/followers`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get User Following
     * Route to GET all users following a given user.
     * @param xTenant 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserFollowing(xTenant: string, userId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<User1 | Project1>>;
    public getUserFollowing(xTenant: string, userId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<User1 | Project1>>>;
    public getUserFollowing(xTenant: string, userId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<User1 | Project1>>>;
    public getUserFollowing(xTenant: string, userId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getUserFollowing.');
        }
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getUserFollowing.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<User1 | Project1>>(`${this.configuration.basePath}/users/${encodeURIComponent(String(userId))}/following`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Workflow Followers
     * Route to GET all users following a given workflow.
     * @param xTenant 
     * @param workflowId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkflowFollowers(xTenant: string, workflowId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<User1>>;
    public getWorkflowFollowers(xTenant: string, workflowId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<User1>>>;
    public getWorkflowFollowers(xTenant: string, workflowId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<User1>>>;
    public getWorkflowFollowers(xTenant: string, workflowId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getWorkflowFollowers.');
        }
        if (workflowId === null || workflowId === undefined) {
            throw new Error('Required parameter workflowId was null or undefined when calling getWorkflowFollowers.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('x-tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<User1>>(`${this.configuration.basePath}/workflows/${encodeURIComponent(String(workflowId))}/followers`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
