/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Project1AllOf { 
    type?: Project1AllOf.TypeEnum;
}
export namespace Project1AllOf {
    export type TypeEnum = 'project';
    export const TypeEnum = {
        Project: 'project' as TypeEnum
    };
}


