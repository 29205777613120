/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CsvReader } from './csvReader';
import { AzureConnector } from './azureConnector';
import { LocalConnector } from './localConnector';
import { ParquetReader } from './parquetReader';
import { S3Connector } from './s3Connector';


export interface ReadFileParams { 
    /**
     * Type of object file.
     */
    type?: ReadFileParams.TypeEnum;
    /**
     * Type of connector (local, S3, Azure).
     */
    connector: S3Connector | AzureConnector | LocalConnector;
    /**
     * List of dictionaries representing the columns         (name and type). For each dictionnary the \'name\' represents the column         name and the \'type\' their corresponding type.
     */
    structure?: Array<{ [key: string]: string; }>;
    /**
     * Options for reading a file (header, file path, separator,         delimiter, ...).
     */
    object: CsvReader | ParquetReader;
}
export namespace ReadFileParams {
    export type TypeEnum = 'file';
    export const TypeEnum = {
        File: 'file' as TypeEnum
    };
}


