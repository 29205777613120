/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SQLDataReader { 
    /**
     * URL to connect to the SQL server.
     */
    url: string;
    /**
     * Port to connect to the SQL server.
     */
    port?: string;
    /**
     * Name of the database in the SQL server.
     */
    db: string;
    /**
     * Logical structure defining the organization,         relationships, and attributes of the database. The public schema is the         default schema where all the new tables are created.
     */
    schema_db?: string;
    /**
     * Name of the table in the database.
     */
    table: string;
    /**
     * Username to connect to the SQL server.
     */
    user: string;
    /**
     * Password to connect to the SQL server.
     */
    password: string;
    /**
     * Type of database connection
     */
    type?: SQLDataReader.TypeEnum;
}
export namespace SQLDataReader {
    export type TypeEnum = 'jdbc';
    export const TypeEnum = {
        Jdbc: 'jdbc' as TypeEnum
    };
}


