/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ResourceType = 'catalog' | 'database' | 'job' | 'project' | 'table' | 'user' | 'workflow' | 'experiment' | 'endpoint';

export const ResourceType = {
    Catalog: 'catalog' as ResourceType,
    Database: 'database' as ResourceType,
    Job: 'job' as ResourceType,
    Project: 'project' as ResourceType,
    Table: 'table' as ResourceType,
    User: 'user' as ResourceType,
    Workflow: 'workflow' as ResourceType,
    Experiment: 'experiment' as ResourceType,
    Endpoint: 'endpoint' as ResourceType
};

