/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DistinctParams { 
    /**
     * Return dataframe with duplicate rows removed. Certain                 columns can be used for identifying duplicates, by default use                 all of the columns.
     */
    columns?: Array<string>;
}

