/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SQLConnector } from './sQLConnector';
import { DBConnector } from './dBConnector';
import { SQLDataReader } from './sQLDataReader';


export interface ReadSQLParams { 
    /**
     * Type SQL server.
     */
    type?: ReadSQLParams.TypeEnum;
    /**
     * Define parameters to read from a SQL server (URL,                 database, table, port, user, password).
     */
    object: SQLDataReader;
    /**
     * List of dictionaries representing the columns (name and type). For each dictionnary the         \'name\' represents the column name and the \'type\' their corresponding type.
     */
    structure?: Array<{ [key: string]: string; }>;
    /**
     * Type of connector (SQL or database connector)
     */
    connector: SQLConnector | DBConnector;
}
export namespace ReadSQLParams {
    export type TypeEnum = 'sql';
    export const TypeEnum = {
        Sql: 'sql' as TypeEnum
    };
}


