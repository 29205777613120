/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface StarburstOptionsAllOf { 
    type?: string;
    licence?: string;
    number_workers?: number;
    worker_instance_type?: string;
    coordinator_instance_type?: string;
    database_instance_type?: string;
    database_type?: string;
    conf?: { [key: string]: object; };
}

