/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Connect to a SQL server.  Attributes ---------- type : ConnectorType     SQL connector type. options : SQLConnectorOptions     Options to connect to a SQL server.
 */
export interface SQLConnector { 
    /**
     * Connection to a SQL server.
     */
    type?: SQLConnector.TypeEnum;
    /**
     * Options to connect to the S3 bucket.
     */
    options?: object;
}
export namespace SQLConnector {
    export type TypeEnum = 'sql';
    export const TypeEnum = {
        Sql: 'sql' as TypeEnum
    };
}


