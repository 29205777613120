/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AzureConnectorOptions } from './azureConnectorOptions';


/**
 * Connect to an Azure Blob Storage.  Attributes ---------- type : ConnectorType     Azure Blob Storage connector type. options : SQLConnectorOptions     Options to connect to an Azure Blob Storage.
 */
export interface AzureConnector { 
    /**
     * Connect to an Azure Blob Storage.
     */
    type?: AzureConnector.TypeEnum;
    /**
     * Options to connect to an Azure Blob Storage.
     */
    options: AzureConnectorOptions;
}
export namespace AzureConnector {
    export type TypeEnum = 'azure';
    export const TypeEnum = {
        Azure: 'azure' as TypeEnum
    };
}


