/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Map the SaveMode object to a string object.
 */
export type SaveMode = 'overwrite' | 'append';

export const SaveMode = {
    Overwrite: 'overwrite' as SaveMode,
    Append: 'append' as SaveMode
};

