/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ParquetReader { 
    /**
     * Define file type for PARQUET.
     */
    type?: ParquetReader.TypeEnum;
    /**
     * Path to PARQUET file.
     */
    path: string;
}
export namespace ParquetReader {
    export type TypeEnum = 'parquet';
    export const TypeEnum = {
        Parquet: 'parquet' as TypeEnum
    };
}


