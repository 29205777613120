/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Event { 
    id?: string;
    object_type?: string;
    object_id?: string;
    tenant_id?: string;
    type?: string;
    message?: string;
    at?: string;
    metadata?: { [key: string]: object; };
}

