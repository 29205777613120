/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Define a logical operator for train a model.  Attributes ---------- type : str     Type of model (Random Forest, ...).  Methods ------- accept(visitor)     Enables the visitor to visit the task.
 */
export interface RandomForest { 
    /**
     * Random Forest model.
     */
    model?: RandomForest.ModelEnum;
    /**
     * Represent the proportion of the dataset to include in the test split. [0, 1.0]
     */
    test_size: number;
    /**
     * The column of the dataset to predict.
     */
    predict_column: string;
    /**
     * The features columns .
     */
    features_column: Array<string>;
    /**
     * The number of trees in the forest.
     */
    number_trees: number;
    /**
     * The maximum depth of the tree.
     */
    max_depth: number;
    /**
     * Seed to set in order to have reproducible results.
     */
    seed?: number;
}
export namespace RandomForest {
    export type ModelEnum = 'random_forest';
    export const ModelEnum = {
        RandomForest: 'random_forest' as ModelEnum
    };
}


