/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Connector for databases.  Attributes ---------- type : ConnectorType     Database type of connector. options : DBConnectorOptions     Options to connect to a database.
 */
export interface DBConnector { 
    /**
     * Connector type for a database connector like SQL
     */
    type?: DBConnector.TypeEnum;
    /**
     * Options to connect to a database.
     */
    options?: object;
}
export namespace DBConnector {
    export type TypeEnum = 'DB';
    export const TypeEnum = {
        Db: 'DB' as TypeEnum
    };
}


