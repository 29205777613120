/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CountParams { 
    /**
     * Count aggregation.
     */
    type?: CountParams.TypeEnum;
    /**
     * Column(s) to count.
     */
    columns: Array<string>;
}
export namespace CountParams {
    export type TypeEnum = 'count';
    export const TypeEnum = {
        Count: 'count' as TypeEnum
    };
}


