/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Object that allows you to conserve an instance of a session.
 */
export interface Session { 
    tenant_id: string;
    address?: string;
    workspace_id: string;
    id: string;
    last_time_used: string;
    notebook_id?: string;
}

