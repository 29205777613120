/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Options to connect to a S3 bucket.  Attributes ---------- bucket : str     Bucket name. access_key : str     Access key for S3. secret_key : str     Secret key for S3. session_token : str     Session token for S3. endpoint : str     URL endpoint.
 */
export interface S3ConnectorOptions { 
    /**
     * The bucket to be connected to.
     */
    bucket: string;
    /**
     * S3 access key to access to AWS.
     */
    access_key: string;
    /**
     * S3 secret key to access to AWS.
     */
    secret_key: string;
    /**
     * S3 session token to access to AWS.
     */
    session_token?: string;
    /**
     * The endpoint to be connected to.
     */
    endpoint?: string;
    /**
     * The region of the endpoint.
     */
    region_name?: string;
}

