/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Company } from './company';
import { PaymentMethod } from './paymentMethod';
import { Contact } from './contact';


export interface SubscriptionDetailsAllOf { 
    type?: string;
    support_plan?: SubscriptionDetailsAllOf.SupportPlanEnum;
    account?: SubscriptionDetailsAllOf.AccountEnum;
    company?: Company;
    contact?: Contact;
    payment_method?: PaymentMethod;
}
export namespace SubscriptionDetailsAllOf {
    export type SupportPlanEnum = 'basic' | 'developer' | 'business' | 'enterprise';
    export const SupportPlanEnum = {
        Basic: 'basic' as SupportPlanEnum,
        Developer: 'developer' as SupportPlanEnum,
        Business: 'business' as SupportPlanEnum,
        Enterprise: 'enterprise' as SupportPlanEnum
    };
    export type AccountEnum = 'personal' | 'enterprise' | 'gouv';
    export const AccountEnum = {
        Personal: 'personal' as AccountEnum,
        Enterprise: 'enterprise' as AccountEnum,
        Gouv: 'gouv' as AccountEnum
    };
}


