/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Filter values that are smaller than a given value.  Attributes ---------- type : RelationalOperatorType     Type of the filter.
 */
export interface LessThanFilter { 
    /**
     * Left operator for filter.
     */
    left: string;
    /**
     * Right operator for filter.
     */
    right: string;
    /**
     * Operator for LESS THAN filter.
     */
    type?: LessThanFilter.TypeEnum;
}
export namespace LessThanFilter {
    export type TypeEnum = 'less';
    export const TypeEnum = {
        Less: 'less' as TypeEnum
    };
}


