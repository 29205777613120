/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Workflow1 } from './workflow1';
import { User1 } from './user1';


export interface WorkflowActivityAllOf { 
    type?: WorkflowActivityAllOf.TypeEnum;
    actor: User1;
    target: Workflow1;
    verb: string;
}
export namespace WorkflowActivityAllOf {
    export type TypeEnum = 'workflow';
    export const TypeEnum = {
        Workflow: 'workflow' as TypeEnum
    };
}


