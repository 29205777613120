<div class="clr-row clr-justify-content-center">
    <div class="clr-col-3">
        <p class="p4">404 ERROR</p>

        <h1>Page not found</h1>

        <p>The link is broken or the page has moved.</p>
        <button class="btn">
            <a href="/">Back to home page</a>
        </button>
    </div>
    <div class="clr-col-4">
        <img cds-layout="container:fill" src="/assets/404.svg" alt=""/>
    </div>
</div>
