import {Component} from '@angular/core';
import '@cds/core/alert/register.js';
import '@cds/core/modal/register.js';

import '@cds/core/icon/register.js';
import {
    applicationsIcon,
    bellIcon,
    ClarityIcons,
    connectIcon,
    folderOpenIcon,
    historyIcon,
    hostGroupIcon,
    keyIcon,
    linkIcon,
    plusIcon,
    userIcon
} from '@cds/core/icon';
// import Swiper core and required modules
import SwiperCore, {Pagination} from "swiper";
import {FormGroup} from "@angular/forms";

ClarityIcons.addIcons(historyIcon, bellIcon, applicationsIcon, linkIcon, connectIcon, userIcon, keyIcon, hostGroupIcon, plusIcon, folderOpenIcon);

// install Swiper modules
SwiperCore.use([Pagination]);

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent {

    tenantForm: FormGroup;
    loading = false;
    submitted = false;
    error = '';

    constructor() {
    }

    ngOnInit() {
    }

}
