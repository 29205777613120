/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Filter values that are in a list or a string.  Attributes ---------- type : RelationalOperatorType     Type of the filter.
 */
export interface InFilter { 
    /**
     * Left operator for filter.
     */
    left: string;
    right: string | Array<string>;
    /**
     * Operator for IN list/range filter.
     */
    type?: InFilter.TypeEnum;
}
export namespace InFilter {
    export type TypeEnum = 'in';
    export const TypeEnum = {
        In: 'in' as TypeEnum
    };
}


