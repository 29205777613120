/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Connect to a local folder.  Attributes ---------- type : ConnectorType     Local type of connector. _prefix : str     Prefix to access the folder (default: empty string).
 */
export interface LocalConnector { 
    /**
     * Connection to a local folder.
     */
    type?: LocalConnector.TypeEnum;
}
export namespace LocalConnector {
    export type TypeEnum = 'local';
    export const TypeEnum = {
        Local: 'local' as TypeEnum
    };
}


