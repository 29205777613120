import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { ActionService } from './api/action.service';
import { ActivityService } from './api/activity.service';
import { ApplicationService } from './api/application.service';
import { AssignmentService } from './api/assignment.service';
import { BridgeService } from './api/bridge.service';
import { BucketService } from './api/bucket.service';
import { CatalogService } from './api/catalog.service';
import { CodeService } from './api/code.service';
import { CostService } from './api/cost.service';
import { DataService } from './api/data.service';
import { DatabaseService } from './api/database.service';
import { DatawarehouseService } from './api/datawarehouse.service';
import { DeviceService } from './api/device.service';
import { EndpointService } from './api/endpoint.service';
import { EnrollmentService } from './api/enrollment.service';
import { EnvService } from './api/env.service';
import { EstimationService } from './api/estimation.service';
import { EventService } from './api/event.service';
import { ExperimentService } from './api/experiment.service';
import { FieldService } from './api/field.service';
import { FirewallService } from './api/firewall.service';
import { FirewallRulesService } from './api/firewallRules.service';
import { FollowService } from './api/follow.service';
import { GraphService } from './api/graph.service';
import { GroupService } from './api/group.service';
import { HistoryService } from './api/history.service';
import { IdentityService } from './api/identity.service';
import { IdentityProviderService } from './api/identityProvider.service';
import { InfrastructureService } from './api/infrastructure.service';
import { InstanceTypeService } from './api/instanceType.service';
import { InvoiceService } from './api/invoice.service';
import { JobService } from './api/job.service';
import { LayerService } from './api/layer.service';
import { LibraryService } from './api/library.service';
import { MarketplaceService } from './api/marketplace.service';
import { MessageService } from './api/message.service';
import { ModelService } from './api/model.service';
import { NotificationService } from './api/notification.service';
import { PaymentmethodService } from './api/paymentmethod.service';
import { PermissionService } from './api/permission.service';
import { PriceService } from './api/price.service';
import { ProjectService } from './api/project.service';
import { QuotaService } from './api/quota.service';
import { RoleService } from './api/role.service';
import { RunService } from './api/run.service';
import { RuntimeService } from './api/runtime.service';
import { SearchService } from './api/search.service';
import { SecretService } from './api/secret.service';
import { SessionsService } from './api/sessions.service';
import { StreamService } from './api/stream.service';
import { StripeService } from './api/stripe.service';
import { StyleService } from './api/style.service';
import { SubscriptionService } from './api/subscription.service';
import { TableService } from './api/table.service';
import { TasksService } from './api/tasks.service';
import { TenantService } from './api/tenant.service';
import { TicketService } from './api/ticket.service';
import { TransactionService } from './api/transaction.service';
import { UsageService } from './api/usage.service';
import { UserService } from './api/user.service';
import { VerificationService } from './api/verification.service';
import { WorkflowService } from './api/workflow.service';
import { WorkspaceService } from './api/workspace.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
