/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OrderParams { 
    /**
     * List of dictionary. For each element the \'name\'         corresponds to the columns to order and         the \'direction\' the direction for sorting (asc or desc).
     */
    columns: Array<{ [key: string]: string; }>;
}

