/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Map the dag input to LanguageType.
 */
export type EtlDagVisitorsEnumsLanguageType = 'pyspark' | 'pandas' | 'java' | 'dask' | 'beam' | 'flink';

export const EtlDagVisitorsEnumsLanguageType = {
    Pyspark: 'pyspark' as EtlDagVisitorsEnumsLanguageType,
    Pandas: 'pandas' as EtlDagVisitorsEnumsLanguageType,
    Java: 'java' as EtlDagVisitorsEnumsLanguageType,
    Dask: 'dask' as EtlDagVisitorsEnumsLanguageType,
    Beam: 'beam' as EtlDagVisitorsEnumsLanguageType,
    Flink: 'flink' as EtlDagVisitorsEnumsLanguageType
};

