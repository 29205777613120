/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Build { 
    id?: string;
    initiator?: string;
    name?: string;
    description?: string;
    status?: string;
    created?: string;
    updated?: string;
    parameters?: Array<string>;
    metadata?: { [key: string]: object; };
}

