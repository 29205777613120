/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InstanceType { 
    id?: string;
    generation?: number;
    family?: string;
    cpus?: number;
    memory?: string;
    storage?: string;
    gpus?: number;
    gpu_memory?: string;
}

