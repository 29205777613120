/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Filter values that contain a sub-string.  Attributes ---------- type : RelationalOperatorType     Type of the filter.
 */
export interface LikeFilter { 
    /**
     * Left operator for filter.
     */
    left: string;
    /**
     * Right operator for filter.
     */
    right: string;
    /**
     * Operator for LIKE filter.
     */
    type?: LikeFilter.TypeEnum;
}
export namespace LikeFilter {
    export type TypeEnum = 'like';
    export const TypeEnum = {
        Like: 'like' as TypeEnum
    };
}


