/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { Experiment } from '../model/models';
import { ExperimentPage } from '../model/models';
import { ExperimentRun } from '../model/models';
import { ExperimentRunPage } from '../model/models';
import { Patch } from '../model/models';
import { RunMetric } from '../model/models';
import { RunParam } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ExperimentService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create a experiment
     * @param xTenant 
     * @param projectId Id of the project
     * @param experiment The experiment to be created
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createExperiment(xTenant: string, projectId: string, experiment: Experiment, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experiment+json'}): Observable<Experiment>;
    public createExperiment(xTenant: string, projectId: string, experiment: Experiment, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experiment+json'}): Observable<HttpResponse<Experiment>>;
    public createExperiment(xTenant: string, projectId: string, experiment: Experiment, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experiment+json'}): Observable<HttpEvent<Experiment>>;
    public createExperiment(xTenant: string, projectId: string, experiment: Experiment, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experiment+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling createExperiment.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling createExperiment.');
        }
        if (experiment === null || experiment === undefined) {
            throw new Error('Required parameter experiment was null or undefined when calling createExperiment.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.experiment+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.graal.systems.v1.experiment+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Experiment>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/experiments`,
            experiment,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a run experiment
     * @param xTenant 
     * @param projectId Id of the project
     * @param experimentId Id of the experiment
     * @param experimentRun The run to be created
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createRunForExperiment(xTenant: string, projectId: string, experimentId: string, experimentRun: ExperimentRun, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experimentrun+json'}): Observable<ExperimentRun>;
    public createRunForExperiment(xTenant: string, projectId: string, experimentId: string, experimentRun: ExperimentRun, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experimentrun+json'}): Observable<HttpResponse<ExperimentRun>>;
    public createRunForExperiment(xTenant: string, projectId: string, experimentId: string, experimentRun: ExperimentRun, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experimentrun+json'}): Observable<HttpEvent<ExperimentRun>>;
    public createRunForExperiment(xTenant: string, projectId: string, experimentId: string, experimentRun: ExperimentRun, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experimentrun+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling createRunForExperiment.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling createRunForExperiment.');
        }
        if (experimentId === null || experimentId === undefined) {
            throw new Error('Required parameter experimentId was null or undefined when calling createRunForExperiment.');
        }
        if (experimentRun === null || experimentRun === undefined) {
            throw new Error('Required parameter experimentRun was null or undefined when calling createRunForExperiment.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.experimentrun+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.graal.systems.v1.experimentrun+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ExperimentRun>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/experiments/${encodeURIComponent(String(experimentId))}/runs`,
            experimentRun,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a experiment by an id
     * @param xTenant 
     * @param projectId Id of the project
     * @param experimentId Id of the experiment
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteExperimentById(xTenant: string, projectId: string, experimentId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<any>;
    public deleteExperimentById(xTenant: string, projectId: string, experimentId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpResponse<any>>;
    public deleteExperimentById(xTenant: string, projectId: string, experimentId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpEvent<any>>;
    public deleteExperimentById(xTenant: string, projectId: string, experimentId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling deleteExperimentById.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling deleteExperimentById.');
        }
        if (experimentId === null || experimentId === undefined) {
            throw new Error('Required parameter experimentId was null or undefined when calling deleteExperimentById.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.error+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/experiments/${encodeURIComponent(String(experimentId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a experiment by an id
     * @param xTenant 
     * @param projectId Id of the project
     * @param experimentId Id of the experiment
     * @param runId Id of the run
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteExperimentRunById(xTenant: string, projectId: string, experimentId: string, runId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<any>;
    public deleteExperimentRunById(xTenant: string, projectId: string, experimentId: string, runId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpResponse<any>>;
    public deleteExperimentRunById(xTenant: string, projectId: string, experimentId: string, runId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpEvent<any>>;
    public deleteExperimentRunById(xTenant: string, projectId: string, experimentId: string, runId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling deleteExperimentRunById.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling deleteExperimentRunById.');
        }
        if (experimentId === null || experimentId === undefined) {
            throw new Error('Required parameter experimentId was null or undefined when calling deleteExperimentRunById.');
        }
        if (runId === null || runId === undefined) {
            throw new Error('Required parameter runId was null or undefined when calling deleteExperimentRunById.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.error+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/experiments/${encodeURIComponent(String(experimentId))}/runs/${encodeURIComponent(String(runId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find experiment by Id
     * @param xTenant 
     * @param projectId Id of the project
     * @param experimentId Id of the experiment
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findExperimentById(xTenant: string, projectId: string, experimentId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experiment+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<Experiment>;
    public findExperimentById(xTenant: string, projectId: string, experimentId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experiment+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpResponse<Experiment>>;
    public findExperimentById(xTenant: string, projectId: string, experimentId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experiment+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpEvent<Experiment>>;
    public findExperimentById(xTenant: string, projectId: string, experimentId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experiment+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling findExperimentById.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling findExperimentById.');
        }
        if (experimentId === null || experimentId === undefined) {
            throw new Error('Required parameter experimentId was null or undefined when calling findExperimentById.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.experiment+json',
                'application/vnd.graal.systems.v1.error+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Experiment>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/experiments/${encodeURIComponent(String(experimentId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find experiment run by Id
     * @param xTenant 
     * @param projectId Id of the project
     * @param experimentId Id of the experiment
     * @param runId Id of the run
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findExperimentRunById(xTenant: string, projectId: string, experimentId: string, runId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experimentrun+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<ExperimentRun>;
    public findExperimentRunById(xTenant: string, projectId: string, experimentId: string, runId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experimentrun+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpResponse<ExperimentRun>>;
    public findExperimentRunById(xTenant: string, projectId: string, experimentId: string, runId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experimentrun+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpEvent<ExperimentRun>>;
    public findExperimentRunById(xTenant: string, projectId: string, experimentId: string, runId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experimentrun+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling findExperimentRunById.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling findExperimentRunById.');
        }
        if (experimentId === null || experimentId === undefined) {
            throw new Error('Required parameter experimentId was null or undefined when calling findExperimentRunById.');
        }
        if (runId === null || runId === undefined) {
            throw new Error('Required parameter runId was null or undefined when calling findExperimentRunById.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.experimentrun+json',
                'application/vnd.graal.systems.v1.error+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ExperimentRun>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/experiments/${encodeURIComponent(String(experimentId))}/runs/${encodeURIComponent(String(runId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve all experiments
     * @param xTenant 
     * @param projectId Id of the project
     * @param page 
     * @param size 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findExperiments(xTenant: string, projectId: string, page?: number, size?: number, name?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experiment+json'}): Observable<ExperimentPage>;
    public findExperiments(xTenant: string, projectId: string, page?: number, size?: number, name?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experiment+json'}): Observable<HttpResponse<ExperimentPage>>;
    public findExperiments(xTenant: string, projectId: string, page?: number, size?: number, name?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experiment+json'}): Observable<HttpEvent<ExperimentPage>>;
    public findExperiments(xTenant: string, projectId: string, page?: number, size?: number, name?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experiment+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling findExperiments.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling findExperiments.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (size !== undefined && size !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>size, 'size');
        }
        if (name !== undefined && name !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>name, 'name');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.experiment+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ExperimentPage>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/experiments`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve all runs for a experiment
     * @param xTenant 
     * @param projectId Id of the project
     * @param experimentId Id of the experiment
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findRunsForExperiment(xTenant: string, projectId: string, experimentId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experimentrun+json'}): Observable<ExperimentRunPage>;
    public findRunsForExperiment(xTenant: string, projectId: string, experimentId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experimentrun+json'}): Observable<HttpResponse<ExperimentRunPage>>;
    public findRunsForExperiment(xTenant: string, projectId: string, experimentId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experimentrun+json'}): Observable<HttpEvent<ExperimentRunPage>>;
    public findRunsForExperiment(xTenant: string, projectId: string, experimentId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experimentrun+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling findRunsForExperiment.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling findRunsForExperiment.');
        }
        if (experimentId === null || experimentId === undefined) {
            throw new Error('Required parameter experimentId was null or undefined when calling findRunsForExperiment.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.experimentrun+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ExperimentRunPage>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/experiments/${encodeURIComponent(String(experimentId))}/runs`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Experiment Metrics
     * @param xTenant 
     * @param projectId Id of the project
     * @param experimentId Id of the experiment
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getExperimentById(xTenant: string, projectId: string, experimentId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.runmetric+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<Array<RunMetric>>;
    public getExperimentById(xTenant: string, projectId: string, experimentId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.runmetric+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpResponse<Array<RunMetric>>>;
    public getExperimentById(xTenant: string, projectId: string, experimentId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.runmetric+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpEvent<Array<RunMetric>>>;
    public getExperimentById(xTenant: string, projectId: string, experimentId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.runmetric+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getExperimentById.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getExperimentById.');
        }
        if (experimentId === null || experimentId === undefined) {
            throw new Error('Required parameter experimentId was null or undefined when calling getExperimentById.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.runmetric+json',
                'application/vnd.graal.systems.v1.error+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<RunMetric>>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/experiments/${encodeURIComponent(String(experimentId))}/metrics`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get experiment metrics by Id
     * @param xTenant 
     * @param projectId Id of the project
     * @param experimentId Id of the experiment
     * @param runId Id of the run
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getExperimentRunMetrics(xTenant: string, projectId: string, experimentId: string, runId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.runmetric+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<Array<RunMetric>>;
    public getExperimentRunMetrics(xTenant: string, projectId: string, experimentId: string, runId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.runmetric+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpResponse<Array<RunMetric>>>;
    public getExperimentRunMetrics(xTenant: string, projectId: string, experimentId: string, runId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.runmetric+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpEvent<Array<RunMetric>>>;
    public getExperimentRunMetrics(xTenant: string, projectId: string, experimentId: string, runId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.runmetric+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getExperimentRunMetrics.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getExperimentRunMetrics.');
        }
        if (experimentId === null || experimentId === undefined) {
            throw new Error('Required parameter experimentId was null or undefined when calling getExperimentRunMetrics.');
        }
        if (runId === null || runId === undefined) {
            throw new Error('Required parameter runId was null or undefined when calling getExperimentRunMetrics.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.runmetric+json',
                'application/vnd.graal.systems.v1.error+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<RunMetric>>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/experiments/${encodeURIComponent(String(experimentId))}/runs/${encodeURIComponent(String(runId))}/metrics`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get experiment params by Id
     * @param xTenant 
     * @param projectId Id of the project
     * @param experimentId Id of the experiment
     * @param runId Id of the run
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getExperimentRunParams(xTenant: string, projectId: string, experimentId: string, runId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.runparam+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<Array<RunParam>>;
    public getExperimentRunParams(xTenant: string, projectId: string, experimentId: string, runId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.runparam+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpResponse<Array<RunParam>>>;
    public getExperimentRunParams(xTenant: string, projectId: string, experimentId: string, runId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.runparam+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpEvent<Array<RunParam>>>;
    public getExperimentRunParams(xTenant: string, projectId: string, experimentId: string, runId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.runparam+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getExperimentRunParams.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getExperimentRunParams.');
        }
        if (experimentId === null || experimentId === undefined) {
            throw new Error('Required parameter experimentId was null or undefined when calling getExperimentRunParams.');
        }
        if (runId === null || runId === undefined) {
            throw new Error('Required parameter runId was null or undefined when calling getExperimentRunParams.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.runparam+json',
                'application/vnd.graal.systems.v1.error+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<RunParam>>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/experiments/${encodeURIComponent(String(experimentId))}/runs/${encodeURIComponent(String(runId))}/params`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Log metrics to an experiment run
     * @param xTenant 
     * @param projectId Id of the project
     * @param experimentId Id of the library
     * @param runId Id of the run
     * @param key Key of the metric
     * @param value Value of the metric
     * @param step Step of the metric
     * @param timestamp Timestamp at which to log the metric
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logExperimentRunMetrics(xTenant: string, projectId: string, experimentId: string, runId: string, key: string, value: number, step: number, timestamp: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.runmetric+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<RunMetric>;
    public logExperimentRunMetrics(xTenant: string, projectId: string, experimentId: string, runId: string, key: string, value: number, step: number, timestamp: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.runmetric+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpResponse<RunMetric>>;
    public logExperimentRunMetrics(xTenant: string, projectId: string, experimentId: string, runId: string, key: string, value: number, step: number, timestamp: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.runmetric+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpEvent<RunMetric>>;
    public logExperimentRunMetrics(xTenant: string, projectId: string, experimentId: string, runId: string, key: string, value: number, step: number, timestamp: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.runmetric+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling logExperimentRunMetrics.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling logExperimentRunMetrics.');
        }
        if (experimentId === null || experimentId === undefined) {
            throw new Error('Required parameter experimentId was null or undefined when calling logExperimentRunMetrics.');
        }
        if (runId === null || runId === undefined) {
            throw new Error('Required parameter runId was null or undefined when calling logExperimentRunMetrics.');
        }
        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling logExperimentRunMetrics.');
        }
        if (value === null || value === undefined) {
            throw new Error('Required parameter value was null or undefined when calling logExperimentRunMetrics.');
        }
        if (step === null || step === undefined) {
            throw new Error('Required parameter step was null or undefined when calling logExperimentRunMetrics.');
        }
        if (timestamp === null || timestamp === undefined) {
            throw new Error('Required parameter timestamp was null or undefined when calling logExperimentRunMetrics.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (key !== undefined && key !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>key, 'key');
        }
        if (value !== undefined && value !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>value, 'value');
        }
        if (step !== undefined && step !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>step, 'step');
        }
        if (timestamp !== undefined && timestamp !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>timestamp, 'timestamp');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.runmetric+json',
                'application/vnd.graal.systems.v1.error+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<RunMetric>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/experiments/${encodeURIComponent(String(experimentId))}/runs/${encodeURIComponent(String(runId))}/metrics`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Log params to an experiment run
     * @param xTenant 
     * @param projectId Id of the project
     * @param experimentId Id of the library
     * @param runId Id of the run
     * @param key Key of the param
     * @param value Value of the param
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logExperimentRunParams(xTenant: string, projectId: string, experimentId: string, runId: string, key: string, value: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.runparam+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<RunParam>;
    public logExperimentRunParams(xTenant: string, projectId: string, experimentId: string, runId: string, key: string, value: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.runparam+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpResponse<RunParam>>;
    public logExperimentRunParams(xTenant: string, projectId: string, experimentId: string, runId: string, key: string, value: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.runparam+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpEvent<RunParam>>;
    public logExperimentRunParams(xTenant: string, projectId: string, experimentId: string, runId: string, key: string, value: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.runparam+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling logExperimentRunParams.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling logExperimentRunParams.');
        }
        if (experimentId === null || experimentId === undefined) {
            throw new Error('Required parameter experimentId was null or undefined when calling logExperimentRunParams.');
        }
        if (runId === null || runId === undefined) {
            throw new Error('Required parameter runId was null or undefined when calling logExperimentRunParams.');
        }
        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling logExperimentRunParams.');
        }
        if (value === null || value === undefined) {
            throw new Error('Required parameter value was null or undefined when calling logExperimentRunParams.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (key !== undefined && key !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>key, 'key');
        }
        if (value !== undefined && value !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>value, 'value');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.runparam+json',
                'application/vnd.graal.systems.v1.error+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<RunParam>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/experiments/${encodeURIComponent(String(experimentId))}/runs/${encodeURIComponent(String(runId))}/params`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a experiment
     * @param xTenant 
     * @param projectId Id of the project
     * @param experimentId Id of the experiment
     * @param patch The patch
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateExperiment(xTenant: string, projectId: string, experimentId: string, patch: Array<Patch>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experiment+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<Experiment>;
    public updateExperiment(xTenant: string, projectId: string, experimentId: string, patch: Array<Patch>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experiment+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpResponse<Experiment>>;
    public updateExperiment(xTenant: string, projectId: string, experimentId: string, patch: Array<Patch>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experiment+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpEvent<Experiment>>;
    public updateExperiment(xTenant: string, projectId: string, experimentId: string, patch: Array<Patch>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experiment+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling updateExperiment.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateExperiment.');
        }
        if (experimentId === null || experimentId === undefined) {
            throw new Error('Required parameter experimentId was null or undefined when calling updateExperiment.');
        }
        if (patch === null || patch === undefined) {
            throw new Error('Required parameter patch was null or undefined when calling updateExperiment.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.experiment+json',
                'application/vnd.graal.systems.v1.error+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json;charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<Experiment>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/experiments/${encodeURIComponent(String(experimentId))}`,
            patch,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a experiment run
     * @param xTenant 
     * @param projectId Id of the project
     * @param experimentId Id of the experiment
     * @param runId Id of the experiment run
     * @param patch The patch
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateExperimentRun(xTenant: string, projectId: string, experimentId: string, runId: string, patch: Array<Patch>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experimentrun+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<ExperimentRun>;
    public updateExperimentRun(xTenant: string, projectId: string, experimentId: string, runId: string, patch: Array<Patch>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experimentrun+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpResponse<ExperimentRun>>;
    public updateExperimentRun(xTenant: string, projectId: string, experimentId: string, runId: string, patch: Array<Patch>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experimentrun+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpEvent<ExperimentRun>>;
    public updateExperimentRun(xTenant: string, projectId: string, experimentId: string, runId: string, patch: Array<Patch>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.experimentrun+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling updateExperimentRun.');
        }
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateExperimentRun.');
        }
        if (experimentId === null || experimentId === undefined) {
            throw new Error('Required parameter experimentId was null or undefined when calling updateExperimentRun.');
        }
        if (runId === null || runId === undefined) {
            throw new Error('Required parameter runId was null or undefined when calling updateExperimentRun.');
        }
        if (patch === null || patch === undefined) {
            throw new Error('Required parameter patch was null or undefined when calling updateExperimentRun.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.experimentrun+json',
                'application/vnd.graal.systems.v1.error+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json;charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<ExperimentRun>(`${this.configuration.basePath}/projects/${encodeURIComponent(String(projectId))}/experiments/${encodeURIComponent(String(experimentId))}/runs/${encodeURIComponent(String(runId))}`,
            patch,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
