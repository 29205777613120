/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NotEqualFilter } from './notEqualFilter';
import { OrFilter } from './orFilter';
import { InFilter } from './inFilter';
import { LessOrEqualThanFilter } from './lessOrEqualThanFilter';
import { LikeFilter } from './likeFilter';
import { AndFilter } from './andFilter';
import { BetweenFilter } from './betweenFilter';
import { LessThanFilter } from './lessThanFilter';
import { NotFilter } from './notFilter';
import { GreaterThanFilter } from './greaterThanFilter';
import { EqualFilter } from './equalFilter';
import { NotNullFilter } from './notNullFilter';
import { GreaterOrEqualThanFilter } from './greaterOrEqualThanFilter';


/**
 * Defines a task in a DAG.  Attributes ---------- id : str     Identifier of a task. params : Params     Parameters of a task.  Methods ------- accept(visitor)     Visit a task using a specified visitor. to_node()     Returns the information about the task (id and parameters). to_edge()     Gets all the dependencies of the task.
 */
export interface FilterTask { 
    /**
     * Identifier of the task.
     */
    id: string;
    /**
     * Parameters of the filter task.
     */
    params: GreaterOrEqualThanFilter | LessOrEqualThanFilter | GreaterThanFilter | NotEqualFilter | LessThanFilter | NotNullFilter | BetweenFilter | EqualFilter | LikeFilter | NotFilter | AndFilter | InFilter | OrFilter;
    /**
     * List of all dependencies of the task.
     */
    dependency: Array<string>;
    /**
     * Type of the filter task.
     */
    type?: FilterTask.TypeEnum;
}
export namespace FilterTask {
    export type TypeEnum = 'filter';
    export const TypeEnum = {
        Filter: 'filter' as TypeEnum
    };
}


