/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Patch { 
    op?: Patch.OpEnum;
    from?: string;
    path?: string;
    value?: object;
}
export namespace Patch {
    export type OpEnum = 'add' | 'copy' | 'move' | 'remove' | 'replace' | 'test';
    export const OpEnum = {
        Add: 'add' as OpEnum,
        Copy: 'copy' as OpEnum,
        Move: 'move' as OpEnum,
        Remove: 'remove' as OpEnum,
        Replace: 'replace' as OpEnum,
        Test: 'test' as OpEnum
    };
}


