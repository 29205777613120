/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InlineResponse2002 { 
    status?: InlineResponse2002.StatusEnum;
}
export namespace InlineResponse2002 {
    export type StatusEnum = 'NOT_FOUND' | 'SUCCESS' | 'WRONG' | 'EXPIRED' | 'UPPER_LIMIT';
    export const StatusEnum = {
        NotFound: 'NOT_FOUND' as StatusEnum,
        Success: 'SUCCESS' as StatusEnum,
        Wrong: 'WRONG' as StatusEnum,
        Expired: 'EXPIRED' as StatusEnum,
        UpperLimit: 'UPPER_LIMIT' as StatusEnum
    };
}


