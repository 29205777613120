/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface User { 
    id?: string;
    username?: string;
    firstname?: string;
    lastname?: string;
    email?: string;
    description?: string;
    password?: string;
    origin?: string;
    created?: string;
    updated?: string;
    metadata?: { [key: string]: object; };
    locked?: boolean;
}

