/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WriteFileParams } from './writeFileParams';
import { WriteSQLParams } from './writeSQLParams';
import { SaveModelParams } from './saveModelParams';


/**
 * Defines a task in a DAG.  Attributes ---------- id : str     Identifier of a task. params : Params     Parameters of a task.  Methods ------- accept(visitor)     Visit a task using a specified visitor. to_node()     Returns the information about the task (id and parameters). to_edge()     Gets all the dependencies of the task.
 */
export interface WriteTask { 
    id: string;
    /**
     * Parameters of the write task.
     */
    params: WriteFileParams | WriteSQLParams | SaveModelParams;
    /**
     * List of all dependencies of the task.
     */
    dependency: Array<string>;
    /**
     * Type of the write task.
     */
    type?: WriteTask.TypeEnum;
}
export namespace WriteTask {
    export type TypeEnum = 'write';
    export const TypeEnum = {
        Write: 'write' as TypeEnum
    };
}


