/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User1 } from './user1';
import { Activity } from './activity';
import { Reaction } from './reaction';
import { ExperimentActivityAllOf } from './experimentActivityAllOf';
import { Experiment1 } from './experiment1';


/**
 * Object ExperimentActivity.
 */
export interface ExperimentActivity { 
    data?: object;
    id?: string;
    time?: string;
    reaction_count?: Array<Reaction>;
    type?: ExperimentActivity.TypeEnum;
    actor: User1;
    target: Experiment1;
    verb: string;
}
export namespace ExperimentActivity {
    export type TypeEnum = 'experiment';
    export const TypeEnum = {
        Experiment: 'experiment' as TypeEnum
    };
}


