/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FirewallRule { 
    id?: string;
    description?: string;
    created?: string;
    updated?: string;
    type?: FirewallRule.TypeEnum;
    protocol?: string;
    ports?: string;
    source?: string;
    target?: string;
    metadata?: { [key: string]: object; };
}
export namespace FirewallRule {
    export type TypeEnum = 'ingress' | 'egress';
    export const TypeEnum = {
        Ingress: 'ingress' as TypeEnum,
        Egress: 'egress' as TypeEnum
    };
}


