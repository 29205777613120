import {Injectable} from '@angular/core';

@Injectable()
export class Environment {
    production: boolean = false;

    // urls
    apiUrl: string = "http://tbd";
    identityUrl: string = "http://tbd";
    documentationUrl: string = "http://tbd";
    accessUrl: string = "http://tbd";

    // console
    consoleUrl: string = 'http://tbd';

    platformVersion: string = "unknown";
}
