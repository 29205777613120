/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CsvWriter { 
    /**
     * Delimiter to use between columns.
     */
    separator?: string;
    /**
     * Is the first row the header?
     */
    header?: boolean;
    /**
     * Alias for separator.
     */
    delimiter?: string;
    /**
     * Type of on-the-fly decompression to use.
     */
    compression?: string;
    /**
     * Is the first column an index?
     */
    index?: boolean;
    /**
     * Path to CSV file.
     */
    path: string;
    /**
     * Define file type for CSV.
     */
    type?: CsvWriter.TypeEnum;
}
export namespace CsvWriter {
    export type TypeEnum = 'csv';
    export const TypeEnum = {
        Csv: 'csv' as TypeEnum
    };
}


