import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SwitchPipe} from "./map/switch.pipe";
import {ByteFormatPipe} from "./map/byte_format.pipe";
import {ToBytePipe} from "./map/to_byte.pipe";

@NgModule({
    declarations: [
        SwitchPipe,
        ByteFormatPipe,
        ToBytePipe
    ],
    exports: [
        SwitchPipe,
        ByteFormatPipe,
        ToBytePipe
    ],
    imports: [
        CommonModule
    ]
})
export class PipesModule {
}
