/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RenameParams { 
    /**
     * Name of the column to rename.
     */
    old_name: string;
    /**
     * Replacement name for the column.
     */
    new_name: string;
}

