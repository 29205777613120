/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CostStats { 
    total?: number;
    cumulative?: Array<number>;
    budget?: Array<number>;
    budget_lower_projection?: Array<number>;
    budget_upper_projection?: Array<number>;
    period?: Array<string>;
    projection_period?: Array<string>;
    type?: string;
    by_type?: { [key: string]: number; };
}

