/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { Patch } from '../model/models';
import { Subscription } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create an subscription
     * @param xTenant 
     * @param subscription The subscription to be created
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSubscription(xTenant: string, subscription: Subscription, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.subscription+json'}): Observable<Subscription>;
    public createSubscription(xTenant: string, subscription: Subscription, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.subscription+json'}): Observable<HttpResponse<Subscription>>;
    public createSubscription(xTenant: string, subscription: Subscription, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.subscription+json'}): Observable<HttpEvent<Subscription>>;
    public createSubscription(xTenant: string, subscription: Subscription, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.subscription+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling createSubscription.');
        }
        if (subscription === null || subscription === undefined) {
            throw new Error('Required parameter subscription was null or undefined when calling createSubscription.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.subscription+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.graal.systems.v1.subscription+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Subscription>(`${this.configuration.basePath}/subscriptions`,
            subscription,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a subscription by its id
     * @param xTenant 
     * @param subscriptionId Id of the Subscription
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSubscriptionById(xTenant: string, subscriptionId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<any>;
    public deleteSubscriptionById(xTenant: string, subscriptionId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpResponse<any>>;
    public deleteSubscriptionById(xTenant: string, subscriptionId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpEvent<any>>;
    public deleteSubscriptionById(xTenant: string, subscriptionId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling deleteSubscriptionById.');
        }
        if (subscriptionId === null || subscriptionId === undefined) {
            throw new Error('Required parameter subscriptionId was null or undefined when calling deleteSubscriptionById.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.error+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/subscriptions/${encodeURIComponent(String(subscriptionId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve all subscription
     * @param xTenant 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findSubscription(xTenant: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.subscription+json'}): Observable<Array<Subscription>>;
    public findSubscription(xTenant: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.subscription+json'}): Observable<HttpResponse<Array<Subscription>>>;
    public findSubscription(xTenant: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.subscription+json'}): Observable<HttpEvent<Array<Subscription>>>;
    public findSubscription(xTenant: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.subscription+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling findSubscription.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.subscription+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<Subscription>>(`${this.configuration.basePath}/subscriptions`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find subscription by Id
     * @param xTenant 
     * @param subscriptionId Id of the subscription
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findSubscriptionById(xTenant: string, subscriptionId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.subscription+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<Subscription>;
    public findSubscriptionById(xTenant: string, subscriptionId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.subscription+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpResponse<Subscription>>;
    public findSubscriptionById(xTenant: string, subscriptionId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.subscription+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpEvent<Subscription>>;
    public findSubscriptionById(xTenant: string, subscriptionId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.subscription+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling findSubscriptionById.');
        }
        if (subscriptionId === null || subscriptionId === undefined) {
            throw new Error('Required parameter subscriptionId was null or undefined when calling findSubscriptionById.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.subscription+json',
                'application/vnd.graal.systems.v1.error+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Subscription>(`${this.configuration.basePath}/subscriptions/${encodeURIComponent(String(subscriptionId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a subscription
     * @param xTenant 
     * @param subscriptionId Id of the Subscription
     * @param patch The patch
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSubscription(xTenant: string, subscriptionId: string, patch: Array<Patch>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.subscription+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<Subscription>;
    public updateSubscription(xTenant: string, subscriptionId: string, patch: Array<Patch>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.subscription+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpResponse<Subscription>>;
    public updateSubscription(xTenant: string, subscriptionId: string, patch: Array<Patch>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.subscription+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpEvent<Subscription>>;
    public updateSubscription(xTenant: string, subscriptionId: string, patch: Array<Patch>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.subscription+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling updateSubscription.');
        }
        if (subscriptionId === null || subscriptionId === undefined) {
            throw new Error('Required parameter subscriptionId was null or undefined when calling updateSubscription.');
        }
        if (patch === null || patch === undefined) {
            throw new Error('Required parameter patch was null or undefined when calling updateSubscription.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.subscription+json',
                'application/vnd.graal.systems.v1.error+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json;charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<Subscription>(`${this.configuration.basePath}/subscriptions/${encodeURIComponent(String(subscriptionId))}`,
            patch,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
