/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NotEqualFilter } from './notEqualFilter';
import { OrFilter } from './orFilter';
import { InFilter } from './inFilter';
import { LessOrEqualThanFilter } from './lessOrEqualThanFilter';
import { LikeFilter } from './likeFilter';
import { BetweenFilter } from './betweenFilter';
import { LessThanFilter } from './lessThanFilter';
import { NotFilter } from './notFilter';
import { GreaterThanFilter } from './greaterThanFilter';
import { EqualFilter } from './equalFilter';
import { NotNullFilter } from './notNullFilter';
import { GreaterOrEqualThanFilter } from './greaterOrEqualThanFilter';


/**
 * Filter values that verify all conditions.  Attributes ---------- type : RelationalOperatorType     Type of the filter. conditions : union_filter     Union of all relational filters.
 */
export interface AndFilter { 
    /**
     * Operator for AND filter.
     */
    type?: AndFilter.TypeEnum;
    /**
     * Condition for AND filter.
     */
    conditions: Array<string | GreaterOrEqualThanFilter | LessOrEqualThanFilter | GreaterThanFilter | NotEqualFilter | LessThanFilter | NotNullFilter | BetweenFilter | EqualFilter | LikeFilter | InFilter | NotFilter | OrFilter | AndFilter>;
}
export namespace AndFilter {
    export type TypeEnum = 'and';
    export const TypeEnum = {
        And: 'and' as TypeEnum
    };
}


