/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Endpoint1 } from './endpoint1';
import { User1 } from './user1';


export interface EndpointActivityAllOf { 
    type?: EndpointActivityAllOf.TypeEnum;
    actor: User1;
    target: Endpoint1;
    verb: string;
}
export namespace EndpointActivityAllOf {
    export type TypeEnum = 'endpoint';
    export const TypeEnum = {
        Endpoint: 'endpoint' as TypeEnum
    };
}


