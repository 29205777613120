import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'byte_format'})
export class ByteFormatPipe implements PipeTransform {

    formatThousandsRegExp = /\B(?=(\d{3})+(?!\d))/g;

    formatDecimalsRegExp = /(?:\.0*|(\.[^0]+)0+)$/;

    map = {
        b: 1,
        kb: 1 << 10,
        mb: 1 << 20,
        gb: 1 << 30,
        tb: Math.pow(1024, 4),
        pb: Math.pow(1024, 5),
    };

    parseRegExp = /^((-|\+)?(\d+(?:\.\d+)?)) *(kb|mb|gb|tb|pb)$/i;

    transform(input: string): string {
        return this.format(this.parse(input), null);
    }

    /**
     * Format the given value in bytes into a string.
     *
     * If the value is negative, it is kept as such. If it is a float,
     * it is rounded.
     *
     * @param {number} value
     * @param {object} [options]
     * @param {number} [options.decimalPlaces=2]
     * @param {number} [options.fixedDecimals=false]
     * @param {string} [options.thousandsSeparator=]
     * @param {string} [options.unit=]
     * @param {string} [options.unitSeparator=]
     *
     * @returns {string|null}
     * @public
     */

    format(value, options) {
        if (!Number.isFinite(value)) {
            return null;
        }

        var mag = Math.abs(value);
        var thousandsSeparator = (options && options.thousandsSeparator) || '';
        var unitSeparator = (options && options.unitSeparator) || '';
        var decimalPlaces = (options && options.decimalPlaces !== undefined) ? options.decimalPlaces : 2;
        var fixedDecimals = Boolean(options && options.fixedDecimals);
        var unit = (options && options.unit) || '';

        if (!unit || !this.map[unit.toLowerCase()]) {
            if (mag >= this.map.pb) {
                unit = 'PB';
            } else if (mag >= this.map.tb) {
                unit = 'TB';
            } else if (mag >= this.map.gb) {
                unit = 'GB';
            } else if (mag >= this.map.mb) {
                unit = 'MB';
            } else if (mag >= this.map.kb) {
                unit = 'KB';
            } else {
                unit = 'B';
            }
        }

        var val = value / this.map[unit.toLowerCase()];
        var str = val.toFixed(decimalPlaces);

        if (!fixedDecimals) {
            str = str.replace(this.formatDecimalsRegExp, '$1');
        }

        if (thousandsSeparator) {
            str = str.replace(this.formatThousandsRegExp, thousandsSeparator);
        }

        return str + unitSeparator + unit;
    }

    /**
     * Parse the string value into an integer in bytes.
     *
     * If no unit is given, it is assumed the value is in bytes.
     *
     * @param {number|string} val
     *
     * @returns {number|null}
     * @public
     */
    parse(val) {
        if (typeof val === 'number' && !isNaN(val)) {
            return val;
        }

        if (typeof val !== 'string') {
            return null;
        }

        // Test if the string passed is valid
        var results = this.parseRegExp.exec(val);
        var floatValue;
        var unit = 'b';

        if (!results) {
            // Nothing could be extracted from the given string
            floatValue = parseInt(val, 10);
            unit = 'b'
        } else {
            // Retrieve the value and the unit
            floatValue = parseFloat(results[1]);
            unit = results[4].toLowerCase();
        }

        return Math.floor(this.map[unit] * floatValue);
    }
}
