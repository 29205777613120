/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Details } from './details';
import { IssueDetailsAllOf } from './issueDetailsAllOf';


export interface IssueDetails extends Details { 
    type?: string;
    resource_type?: string;
    resource_id?: string;
    severity?: IssueDetails.SeverityEnum;
}
export namespace IssueDetails {
    export type SeverityEnum = 'p1_minimal' | 'p2_moderate' | 'p3_critical';
    export const SeverityEnum = {
        P1Minimal: 'p1_minimal' as SeverityEnum,
        P2Moderate: 'p2_moderate' as SeverityEnum,
        P3Critical: 'p3_critical' as SeverityEnum
    };
}


