/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EtlDagSchemasEnumsLanguageType } from './etlDagSchemasEnumsLanguageType';
import { Dag } from './dag';


/**
 * Code generation Request.
 */
export interface CodeAction { 
    type?: CodeAction.TypeEnum;
    language: EtlDagSchemasEnumsLanguageType;
    dag: Dag;
}
export namespace CodeAction {
    export type TypeEnum = 'code';
    export const TypeEnum = {
        Code: 'code' as TypeEnum
    };
}


