/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EtlDagSchemasEnumsLanguageType } from './etlDagSchemasEnumsLanguageType';
import { Dag } from './dag';


/**
 * Validity Request.
 */
export interface ValidityAction { 
    type?: ValidityAction.TypeEnum;
    language?: EtlDagSchemasEnumsLanguageType;
    dag: Dag;
}
export namespace ValidityAction {
    export type TypeEnum = 'verify';
    export const TypeEnum = {
        Verify: 'verify' as TypeEnum
    };
}


