/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RandomForest } from './randomForest';


/**
 * Defines a task in a DAG.  Attributes ---------- id : str     Identifier of a task. params : Params     Parameters of a task.  Methods ------- accept(visitor)     Visit a task using a specified visitor. to_node()     Returns the information about the task (id and parameters). to_edge()     Gets all the dependencies of the task.
 */
export interface TrainModelTask { 
    /**
     * Identifier of the task.
     */
    id: string;
    /**
     * List of model to compute.
     */
    params: RandomForest;
    /**
     * List of all dependencies of the task.
     */
    dependency: Array<string>;
    /**
     * Type of the train model task.
     */
    type?: TrainModelTask.TypeEnum;
}
export namespace TrainModelTask {
    export type TypeEnum = 'train_model';
    export const TypeEnum = {
        TrainModel: 'train_model' as TypeEnum
    };
}


