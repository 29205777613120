/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JoblibWriter } from './joblibWriter';
import { LocalConnector } from './localConnector';
import { PickleWriter } from './pickleWriter';
import { S3Connector } from './s3Connector';


export interface SaveModelParams { 
    /**
     * Type of object model.
     */
    type?: SaveModelParams.TypeEnum;
    /**
     * Type of connector
     */
    connector: S3Connector | LocalConnector;
    /**
     * Options for reading a file (header, file path, separator,         delimiter, ...).
     */
    object: PickleWriter | JoblibWriter;
}
export namespace SaveModelParams {
    export type TypeEnum = 'model';
    export const TypeEnum = {
        Model: 'model' as TypeEnum
    };
}


