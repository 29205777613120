/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Usage { 
    product_type?: Usage.ProductTypeEnum;
    quantity?: number;
    product_reference?: string;
    created?: string;
    dimensions?: { [key: string]: string; };
}
export namespace Usage {
    export type ProductTypeEnum = 'INSTANCE' | 'EGRESS' | 'LOGS' | 'SUPPORT';
    export const ProductTypeEnum = {
        Instance: 'INSTANCE' as ProductTypeEnum,
        Egress: 'EGRESS' as ProductTypeEnum,
        Logs: 'LOGS' as ProductTypeEnum,
        Support: 'SUPPORT' as ProductTypeEnum
    };
}


