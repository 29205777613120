import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'to_byte'})
export class ToBytePipe implements PipeTransform {

    formatThousandsRegExp = /\B(?=(\d{3})+(?!\d))/g;

    formatDecimalsRegExp = /(?:\.0*|(\.[^0]+)0+)$/;

    map = {
        b: 1,
        kb: 1 << 10,
        mb: 1 << 20,
        gb: 1 << 30,
        tb: Math.pow(1024, 4),
        pb: Math.pow(1024, 5),
    };

    parseRegExp = /^((-|\+)?(\d+(?:\.\d+)?)) *(kb|mb|gb|tb|pb)$/i;

    transform(input: number|string): number|string {
        return this.parse(input);
    }

    /**
     * Parse the string value into an integer in bytes.
     *
     * If no unit is given, it is assumed the value is in bytes.
     *
     * @param {number|string} val
     *
     * @returns {number|null}
     * @public
     */
    parse(val:number|string) {
        if (typeof val === 'number' && !isNaN(val)) {
            return val;
        }

        if (typeof val !== 'string') {
            return null;
        }

        // Test if the string passed is valid
        var results = this.parseRegExp.exec(val);
        var floatValue;
        var unit = 'b';

        if (!results) {
            // Nothing could be extracted from the given string
            floatValue = parseInt(val, 10);
            unit = 'b'
        } else {
            // Retrieve the value and the unit
            floatValue = parseFloat(results[1]);
            unit = results[4].toLowerCase();
        }

        return Math.floor(this.map[unit] * floatValue);
    }
}
