/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { LogEntry } from '../model/models';
import { Patch } from '../model/models';
import { Workspace } from '../model/models';
import { WorkspacePage } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create workspace
     * @param xTenant 
     * @param workspace The workspace to be created
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createWorkspace(xTenant: string, workspace: Workspace, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.workspace+json'}): Observable<Workspace>;
    public createWorkspace(xTenant: string, workspace: Workspace, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.workspace+json'}): Observable<HttpResponse<Workspace>>;
    public createWorkspace(xTenant: string, workspace: Workspace, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.workspace+json'}): Observable<HttpEvent<Workspace>>;
    public createWorkspace(xTenant: string, workspace: Workspace, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.workspace+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling createWorkspace.');
        }
        if (workspace === null || workspace === undefined) {
            throw new Error('Required parameter workspace was null or undefined when calling createWorkspace.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.workspace+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.graal.systems.v1.workspace+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Workspace>(`${this.configuration.basePath}/workspaces`,
            workspace,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a workspace by an id
     * @param xTenant 
     * @param workspaceId Id of the workspace
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteWorkspaceById(xTenant: string, workspaceId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<any>;
    public deleteWorkspaceById(xTenant: string, workspaceId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpResponse<any>>;
    public deleteWorkspaceById(xTenant: string, workspaceId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpEvent<any>>;
    public deleteWorkspaceById(xTenant: string, workspaceId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.error+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling deleteWorkspaceById.');
        }
        if (workspaceId === null || workspaceId === undefined) {
            throw new Error('Required parameter workspaceId was null or undefined when calling deleteWorkspaceById.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.error+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/workspaces/${encodeURIComponent(String(workspaceId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find workspace by Id
     * @param xTenant 
     * @param workspaceId Id of the workspace
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findWorkspaceById(xTenant: string, workspaceId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.workspace+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<Workspace>;
    public findWorkspaceById(xTenant: string, workspaceId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.workspace+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpResponse<Workspace>>;
    public findWorkspaceById(xTenant: string, workspaceId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.workspace+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpEvent<Workspace>>;
    public findWorkspaceById(xTenant: string, workspaceId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.workspace+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling findWorkspaceById.');
        }
        if (workspaceId === null || workspaceId === undefined) {
            throw new Error('Required parameter workspaceId was null or undefined when calling findWorkspaceById.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.workspace+json',
                'application/vnd.graal.systems.v1.error+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Workspace>(`${this.configuration.basePath}/workspaces/${encodeURIComponent(String(workspaceId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve all workspaces
     * @param xTenant 
     * @param page 
     * @param size 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findWorkspaces(xTenant: string, page?: number, size?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.workspace+json'}): Observable<WorkspacePage>;
    public findWorkspaces(xTenant: string, page?: number, size?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.workspace+json'}): Observable<HttpResponse<WorkspacePage>>;
    public findWorkspaces(xTenant: string, page?: number, size?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.workspace+json'}): Observable<HttpEvent<WorkspacePage>>;
    public findWorkspaces(xTenant: string, page?: number, size?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.workspace+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling findWorkspaces.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (size !== undefined && size !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>size, 'size');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.workspace+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<WorkspacePage>(`${this.configuration.basePath}/workspaces`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get logs
     * @param xTenant 
     * @param workspaceId Id of the workspace
     * @param cursor The cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLogsForWorkspace(xTenant: string, workspaceId: string, cursor?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.log+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<Array<LogEntry>>;
    public getLogsForWorkspace(xTenant: string, workspaceId: string, cursor?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.log+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpResponse<Array<LogEntry>>>;
    public getLogsForWorkspace(xTenant: string, workspaceId: string, cursor?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.log+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpEvent<Array<LogEntry>>>;
    public getLogsForWorkspace(xTenant: string, workspaceId: string, cursor?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.log+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getLogsForWorkspace.');
        }
        if (workspaceId === null || workspaceId === undefined) {
            throw new Error('Required parameter workspaceId was null or undefined when calling getLogsForWorkspace.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (cursor !== undefined && cursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cursor, 'cursor');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.log+json',
                'application/vnd.graal.systems.v1.error+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<LogEntry>>(`${this.configuration.basePath}/workspaces/${encodeURIComponent(String(workspaceId))}/logs`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a workspace
     * @param xTenant 
     * @param workspaceId Id of the workspace
     * @param patch The patch
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateWorkspace(xTenant: string, workspaceId: string, patch: Array<Patch>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.workspace+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<Workspace>;
    public updateWorkspace(xTenant: string, workspaceId: string, patch: Array<Patch>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.workspace+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpResponse<Workspace>>;
    public updateWorkspace(xTenant: string, workspaceId: string, patch: Array<Patch>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.workspace+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<HttpEvent<Workspace>>;
    public updateWorkspace(xTenant: string, workspaceId: string, patch: Array<Patch>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.graal.systems.v1.workspace+json' | 'application/vnd.graal.systems.v1.error+json'}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling updateWorkspace.');
        }
        if (workspaceId === null || workspaceId === undefined) {
            throw new Error('Required parameter workspaceId was null or undefined when calling updateWorkspace.');
        }
        if (patch === null || patch === undefined) {
            throw new Error('Required parameter patch was null or undefined when calling updateWorkspace.');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.graal.systems.v1.workspace+json',
                'application/vnd.graal.systems.v1.error+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json;charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<Workspace>(`${this.configuration.basePath}/workspaces/${encodeURIComponent(String(workspaceId))}`,
            patch,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload files in a workspace
     * @param xTenant 
     * @param workspaceId Id of the bucket
     * @param path path
     * @param filename 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadFileInWorkspace(xTenant: string, workspaceId: string, path?: string, filename?: Array<Blob>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public uploadFileInWorkspace(xTenant: string, workspaceId: string, path?: string, filename?: Array<Blob>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public uploadFileInWorkspace(xTenant: string, workspaceId: string, path?: string, filename?: Array<Blob>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public uploadFileInWorkspace(xTenant: string, workspaceId: string, path?: string, filename?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling uploadFileInWorkspace.');
        }
        if (workspaceId === null || workspaceId === undefined) {
            throw new Error('Required parameter workspaceId was null or undefined when calling uploadFileInWorkspace.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (path !== undefined && path !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>path, 'path');
        }

        let headers = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            headers = headers.set('X-Tenant', String(xTenant));
        }

        let credential: string | undefined;
        // authentication (internal) required
        credential = this.configuration.lookupCredential('internal');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (filename) {
            if (useForm) {
                filename.forEach((element) => {
                    formParams = formParams.append('filename', <any>element) as any || formParams;
            })
            } else {
                formParams = formParams.append('filename', filename.join(COLLECTION_FORMATS['csv'])) as any || formParams;
            }
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/workspaces/${encodeURIComponent(String(workspaceId))}/files`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
