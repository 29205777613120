/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User1 } from './user1';
import { Job1 } from './job1';
import { Activity } from './activity';
import { JobActivityAllOf } from './jobActivityAllOf';
import { Reaction } from './reaction';


/**
 * Object JobActivity.
 */
export interface JobActivity { 
    data?: object;
    id?: string;
    time?: string;
    reaction_count?: Array<Reaction>;
    type?: JobActivity.TypeEnum;
    actor: User1;
    target: Job1;
    verb: string;
}
export namespace JobActivity {
    export type TypeEnum = 'job';
    export const TypeEnum = {
        Job: 'job' as TypeEnum
    };
}


