/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface JoblibWriter { 
    /**
     * Name of the path.
     */
    path: string;
    /**
     * Define file type for Joblib.
     */
    type?: JoblibWriter.TypeEnum;
}
export namespace JoblibWriter {
    export type TypeEnum = 'joblib';
    export const TypeEnum = {
        Joblib: 'joblib' as TypeEnum
    };
}


